import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import RolesContactsManagement from "./RolesContactsManagement";
import * as yup from 'yup';
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import useDataService from "../../hooks/useDataService";

function Program(props) {

    let params = useParams();
    const { getProgramById, updateProgram } = useDataService();

    const [loading, setLoading] = useState(true);
    const [program, setProgram] = useState({});
    const [error, setError] = useState('');

    const [putError, setPutError] = useState("");
    const [edit, setEdit] = useState(false);

    const navigate = useNavigate();

    const programSchema = yup.object().shape({
        name: yup.string().required('Name is required').max(60),
        code: yup.string().required('Code must be selected').max(10)
    })

    const editProgram = (formData, setSubmitting, resetForm) => {
        setPutError();
        setSubmitting(true);
        updateProgram(params.deptId, params.progId, formData)
        .then(prog => {
            setProgram(prog);
            setEdit(false);
        })
        .catch(error => {
            console.log(error);
            setPutError(error.message.toString());
        }) 
        .finally(() => setSubmitting(false));
    }

    useEffect(() => {
        setLoading(true);
        getProgramById(params.deptId, params.progId)
        .then(data => {
            if (!data) throw {message: 'No such program.'} 
            setProgram(data);
        })
        .catch(err => {
            setError(err.message.toString());
        })
        .finally(() => {
            setLoading(false);
        });
    }, [params.deptId, params.progId]);

    return (
        <Container className="mt-4">
            {/* <Button variant="outline-danger" className="mb-4" onClick={()=>goToPrograms()}>
                <span>&lt; Back to programs</span>
            </Button> */}
            { loading 
            ?
            <p>Loading...</p>
            : error == "" 
            ?
            <>
            <Container>
                <Row className="justify-content-between mb-3">
                    <Col>
                        <h4>Program Information</h4>
                    </Col>
                    <Col md="auto">
                        <Button variant= {edit ? 'secondary' : 'outline-secondary'} onClick={()=>setEdit(!edit)}>
                            {edit ? 
                                "Cancel" 
                                : <span><FontAwesomeIcon icon={faPencilAlt}/> Edit</span>
                            }
                        </Button>
                    </Col>
                </Row>
                { edit ? 
                <>
                    <Formik
                        initialValues={{
                            name: program.name ? program.name : "",
                            code: program.code ? program.code : ""
                        }}
                        validationSchema={programSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => editProgram(values, setSubmitting, resetForm)}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            isSubmitting
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={2}>
                                        <Form.Group as={Col} controlId="name" label="User's name">
                                            <Form.Label>Program Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="code"
                                                placeholder="PRG"
                                                value={values.code}
                                                onChange={handleChange}
                                                isInvalid={!!errors.code}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group as={Col} controlId="name" label="User's name">
                                            <Form.Label>Program Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder="Program name"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} className="align-self-end text-end">
                                        <Button className="w-100" disabled={isSubmitting} type="submit" variant="primary">Confirm</Button>
                                    </Col>
                                </Row>
                                <div className={`invalid-feedback ${putError ? 'd-block' : 'd-none'}`}>
                                    {putError}
                                </div>
                            </Form>
                        )}

                    </Formik>
                </>
                :
                <>
                    <p>Program code: {program.code}</p>
                    <p>Program name: {program.name}</p>
                </> }
                </Container>
                <RolesContactsManagement type="PROG_CONTACTS"/>
            </>
            : 
            <>
                <p>Whoops! An error...</p>
                <p>{error}</p>
            </>
            }
        </Container>
    )
}
export default Program;