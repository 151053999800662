import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import useDataService from "../../hooks/useDataService";
import CasesListSection from "./CasesListSection";
import ColumnFilter from "./ColumnFilter";
import ColumnSizes from "./ColumnSizes";
import SortableColumnHeader from "./SortableColumnHeader";

function CasesList({
    columns,
    endpoint,
    header,
    defaultSort,
    showStatuses,
    selectionForm,
    ...props
}) {

    // import data service functions
    const { getCases, getAllSemesters, getStatuses } = useDataService();

    const [statuses, setStatuses] = useState([]);   // statuses returned from API - will appear in the "filter by status" dropdown
    const [cases, setCases] = useState([])          // cases returned from API
    const [semesters, setSemesters] = useState([]); // semesters returned from API

    const [activeSort, setSort] = useState({
        colIndex: defaultSort.colIndex,
        asc: defaultSort.asc
    })
    const [filter, setFilter] = useState(false);

    const initialFilters = {
        caseID: "",
        status: selectionForm ? ['4'] : [],
        startDate: "",
        endDate: "",
        studentName: "",
        submittedByProfessor: "",
        courseCode: "",
        evidence: "all",
        specs: "all"
    };

    // form for filtering requirements
    const filterForm = useFormik({
        initialValues: initialFilters
    });

    // form for semester selection
    const semesterForm = useFormik({
        initialValues: {
            semester: "Winter 2022"
        }
    });

    /* update sort criteria to sort on specific column by index.
    if column index is same as before, just toggle sort direction
    */
    const toggleSort = colIndex => {
        setSort({
            colIndex: colIndex,
            asc: colIndex === activeSort.colIndex ? !activeSort.asc : true
        })
    }

    // sorting rules
    const comparator = (case1, case2) => {
        let res = 0;
        switch (columns[activeSort.colIndex]) {
            case 'Case #':
            case 'Select Case':
                res = case1.groupID - case2.groupID;
                break;
            case 'Status':
                res = case1.status.id - case2.status.id;
                break;
            case 'Date Created':
                res = new Date(case1.submitted_date) - new Date(case2.submitted_date);
                break;
            case 'Deadline':
                res = new Date(case1.deadline) - new Date(case2.deadline);
            case 'Student':
                res = case1.studentName.localeCompare(case2.studentName);
                break;
            case 'Professor':
                res = case1.submittedByProfessor.localeCompare(case2.submittedByProfessor);
                break;
            case 'Course':
                res = case1.courseCode.localeCompare(case2.courseCode);
                break;
            case 'Evidence':
                res = case1.evidenceCount > 0 && case2.evidenceCount <= 0 ? 1 : -1;
                break;
            case 'Assessment Specifications':
                res = case1.specsCount > 0 && case2.specsCount <= 0 ? 1 : -1;
                break;
            default: 
                res = 0;
        }

        if (activeSort.asc === false) {
            res = -res;
        }

        return res;
    }

    // filtering rules
    const filterRules = (c) => {
            let show = false;
            // check caseID
            show = c.groupID.toString().includes(filterForm.values.caseID);

            // check status
            if (show){
                if (filterForm.values.status.length > 0)
                    show = filterForm.values.status.filter(s => c.status.id.includes(s)).length > 0;
                else 
                    show = true;
            }

            // check student name
            if (show) {
                show = c.studentName.toLowerCase().includes(filterForm.values.studentName.toLowerCase());
            }

            // check date range
            if (show) {
                if (filterForm.values.startDate.length > 0 && filterForm.values.endDate.length > 0) {
                    const caseDate = new Date(c.submitted_date)
                    show = caseDate >= new Date(filterForm.values.startDate) && caseDate <= new Date(filterForm.values.endDate);
                }
            }

            // check professor's name
            if (show) {
                show = c.submittedByProfessor.toLowerCase().includes(filterForm.values.submittedByProfessor.toLowerCase());
            }

            // check course code
            if (show) {
                show = c.courseCode.toLowerCase().includes(filterForm.values.courseCode.toLowerCase());
            }

            // check evidence 
            if (show) {
                switch(filterForm.values.evidence) {
                    case 'all':
                        show = true;
                        break;
                    case 'y': 
                        show = c.evidenceCount > 0;
                        break;
                    case 'n': 
                        show = c.evidenceCount === 0;
                        break;
                    default:
                        show = true;
                }
            }

            // check assessment specs 
            if (show) {
                switch(filterForm.values.specs) {
                    case 'all':
                        show = true;
                        break;
                    case 'y': 
                        show = c.specsCount > 0;
                        break;
                    case 'n': 
                        show = c.specsCount === 0;
                        break;
                    default:
                        show = true;
                }
            }

            c.show = show;
            return c;
    }

    // handles "select all" functionality in decision letter list
    const selectAllCases = () => {
        const value = selectionForm.values.caseIDs.length > 0 ? [] : cases.filter(c => c.show).map(c => c.cases.filter(c => c.status.id === 4)).flat().map(c => c.caseID);
        selectionForm.setFieldValue("caseIDs", value);
    }

    // initialize semesters
    useEffect(() => {
        getAllSemesters()
        .then(semesters => setSemesters(semesters))
        .catch(err => console.log(err))
    }, [])

    // initialize statuses
    useEffect(() => {
        getStatuses()
            .then(statuses => setStatuses(statuses))
            .catch(err => console.log(err))
    }, []);

    // fetch the data again every time selected semester changes
    useEffect(() => {
        getCases(endpoint, semesterForm.values.semester)
        .then(data => {
            setCases(data.map(obj => {
                obj.show = true;
                return obj;
            }))
            filterForm.resetForm();
            setSort({
                colIndex: activeSort.colIndex,
                asc: activeSort.asc
            })

            // when list is rendered within decion letters list, filter will be initialized to status = 'Closed' (status ID 4)
            if (selectionForm) {
                filterForm.setFieldValue("status", ['4']);
            }
        })
        .catch(err => console.log(err))
    }, [semesterForm.values.semester])

    // re-sort the list every time sort criteria changes
    useEffect(() => {
        setCases([...cases.sort(comparator)]);
    }, [activeSort])

    // re-filter the list every time filter criteria changes
    useEffect(() => {
        setCases(c => [...cases.map(filterRules)]);
    }, [filterForm.values])

    return (
        <Container className="w-75">
            <h3 className="mt-2 mb-3">{header}</h3>
            <Form noValidate id="semesterForm"/>
            <Row className="mb-2 justify-content-between">
                <Col md="auto">
                    <Button
                        variant={filter ? "secondary" : "outline-secondary"}
                        onClick={() => {setFilter(!filter); filterForm.resetForm(initialFilters);}}
                    >
                        <span>Filter</span>
                        <FontAwesomeIcon icon={faFilter}  className="ms-1"/>
                    </Button>
                </Col>
                <Col md="auto">
                    <Form.Select
                        form="semesterForm"
                        id="semester"
                        name="semester"
                        onChange={semesterForm.handleChange}
                        value={semesterForm.values.semester}
                    >
                        {semesters.map((s, i) => (
                            <option key={i} value={s.semester}>{s.semester}</option>
                        ))}
                    </Form.Select>
                </Col>

            </Row>
            <Form noValidate id="casesFilterForm"></Form>
            <Table bordered responsive>
                <ColumnSizes columns={columns}/>
                <thead>
                    <tr>
                        {
                            columns.map((title, index) => (
                                <th key={index} style={{verticalAlign: 'top'}}>
                                    <SortableColumnHeader
                                        key={index}
                                        text={title}
                                        sorted={activeSort.colIndex === index}
                                        asc={activeSort.asc}
                                        index={index}
                                        toggleSort={toggleSort}
                                        selectionForm={selectionForm}
                                        selectAllCases={selectAllCases}
                                    />
                                    <ColumnFilter
                                        column={title}
                                        filter={filter}
                                        values={filterForm.values}
                                        handleChange={filterForm.handleChange}
                                        statuses={statuses}
                                    />
                                    {title === 'Select Case' && selectionForm &&
                                        <Form.Check
                                            type="checkbox"
                                            name="caseIDs"
                                            id={`all-cases-select`}
                                            onChange={selectAllCases}
                                            checked={selectionForm.values.caseIDs.length > 0}
                                            label="All"
                                            value="All"
                                        />
                                    }
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                {cases.filter(c => c.show).map((c, index) => (
                    <CasesListSection 
                        key={index}
                        columns={columns}
                        data={c}
                        showStatuses={showStatuses}
                        selectionForm={selectionForm}
                    />)
                )}
                {cases.filter(c => c.show).length === 0 && 
                    <tbody>
                        <tr>
                            <td colSpan={columns.length} className="text-center"><i>No incidents found</i></td>
                        </tr>
                    </tbody>
                }
            </Table>
        </Container>
    )
}

export default CasesList;