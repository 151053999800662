import CaseDetails from "./CaseDetails";
import Student from "./Student";

export default class FormData {
    facultyName = "";
    facultyEmail = "";
    courseCode = "";
    department = "";
    incidentDate = new Date().toISOString().split("T")[0];
    assessmentName = "";
    semester = "Winter 2022";
    groupCase = false;
    students = [new Student()];
    caseDetails = {
        incidentDescription: "",
        altIncidentDescription: ""
    };

    setFacultyInfo = function(facultyInfo) {
        this.facultyName = facultyInfo.facultyName;
        this.facultyEmail = facultyInfo.facultyEmail;
        this.courseCode = facultyInfo.courseCode;
        this.department = facultyInfo.department;
        this.incidentDate = facultyInfo.incidentDate;
        this.assessmentName = facultyInfo.assessmentName;
        this.semester = facultyInfo.semester;
        
        // handle if group case checkbox was checked/unchecked
        const previousGroupFlag = this.groupCase;
        this.groupCase = facultyInfo.groupCase[0] === 'on';
        if (previousGroupFlag !== this.groupCase) {
            // if this is a group case now, delete per-student case details
            if (this.groupCase) 
                this.students = this.students.map(s => {
                    s.caseDetails = new CaseDetails();
                    return s;
                })
            else // if this is not a group case anymore, delete the shared case details
                this.caseDetails = new CaseDetails();
        }
    };

    setStudents = function(studentInfo) {
        this.caseDetails = studentInfo.caseDetails;
        this.students = studentInfo.students;
    };

    setIncidentInfo = function(incidentInfo) {
        if (this.groupCase) {
            this.caseDetails.setInfo(incidentInfo.incidentDescription, incidentInfo.altIncidentDescription, incidentInfo.specsFiles);
        } else {
            this.students[incidentInfo.studentIndex].caseDetails.setInfo(incidentInfo.incidentDescription, incidentInfo.altIncidentDescription, incidentInfo.specsFiles);
        }
    };

    setEvidenceInfo = function(evidenceInfo) {
        if (this.groupCase) {
            this.caseDetails.evidence = evidenceInfo.evidence;
        } else {
            this.students[evidenceInfo.studentIndex].caseDetails.evidence = evidenceInfo.evidence;
        }
    }
}