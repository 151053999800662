import { Col, Container, Row } from "react-bootstrap";
import { useState, useEffect } from 'react';
// import "./CasesSummary.css";
import useDataService from "../../hooks/useDataService";
import StatPanel from "./StatPanel";

function Dashboard(props) {

  const [summary, setSummary] = useState([]);
  const [error, setError] = useState();

  const {getCasesSummary} = useDataService();

  useEffect(() => {
    getCasesSummary()
    .then(data => setSummary(data))
    .catch(err => setError(err.message.toString()));
  }, []);

  return (
    <Container className="w-75">
        <h3 className="mt-2 mb-3">Dashboard</h3>
        <p>The summaries include incidents that you have access to as a Committee Member.</p>
        <Row md={5}>
            {summary.map((s, i) => (
                <Col>
                    <StatPanel data={s} key={i}/>
                </Col>
            ))}
        </Row>
    </Container>
  );
}

export default Dashboard;