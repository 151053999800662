const BlobService = {
    /**
     * Pushes the file to the Blob Storage
     * @param {Object} data 
     * @param {Object} file FileObject to upload
     * @returns Promise
     */
    uploadFile: (data, file) => {
        return fetch(data.url, {
            method: 'PUT',
            mode: 'cors', 
            headers: {
                'x-ms-blob-type': 'BlockBlob',
                'x-ms-meta-attachmenttype': data.attachmentType
            },
            body: file
        })
    }
};

export default BlobService;