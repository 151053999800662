import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import AccessDenied from "./Errors/AccessDenied";

function PrivateRoute({children, roles, loading}) {
    const user = useContext(UserContext)[0];

    // keep a blank page while the context is initializing
    if (loading) {
        return null;
    }

    // user context is NULL => user is not logged in => redirect to login page
    if (!user) {
        return <Navigate to="/login"/>
    }

    // check if user can access this route with their role, otherwise show "Forbidden"
    if (user && !user.userData.roles.some(r => roles.map(r => r.role).includes(r.role))) {
        return <AccessDenied/>
    }

    // none of the scenarios above returned => user is allowed to see this resource
    return children;
}

export default PrivateRoute;