import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import RolesContactsManagement from "./RolesContactsManagement";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import useDataService from "../../hooks/useDataService";
import EditDepartment from "./EditDepartment";
import DepartmentInfo from "./DepartmentInfo";
import AccessDenied from '../Errors/AccessDenied';
import NotFound from '../Errors/NotFound';


function Department(props) {


    const [editing, setEditing] = useState(false);

    const helpText = {
        guilty: "This text will appear on the final decision letter if a violation of Academic Integrity Policy has occured",
        innocent: "This text will appear on the final decision letter if there has been no Academic Integrity Policy violation"
    }

    return (
        <Container>
            <Row className="justify-content-between mt-3 mb-3">
                <Col>
                    <h4>Department Information</h4>
                </Col>
                <Col md="auto">
                    <Button variant={editing ? 'secondary' : 'outline-secondary'} onClick={() => setEditing(!editing)}>
                        {editing ?
                            "Cancel"
                            : <span><FontAwesomeIcon icon={faPencilAlt} /> Edit</span>

                        }
                    </Button>
                </Col>
            </Row>

            {editing ?
                <EditDepartment
                    department={props.department}
                    setEditing={setEditing}
                    setDepartment={props.setDepartment}
                    helpText={helpText}
                />
                : <DepartmentInfo department={props.department} helpText={helpText} />
            }

            <RolesContactsManagement type="DEPT_ROLES" />
            <RolesContactsManagement type="DEPT_CONTACTS" />
        </Container>
    )
}

export default Department;