import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Container, Table, Button, Row, Col, Form } from "react-bootstrap";
import useDataService from "../../hooks/useDataService";
import ColumnFilter from "./ColumnFilter";
import SortableColumnHeader from "../CasesList/SortableColumnHeader";
import { holdItemsList } from "../../helpers/helpers";


function AddPriors(props) {

    const tableHeaders = [
        'Case #',
        'Student Name',
        'Student Number',
        'Student Email',
        'Program',
        'Prior Hold Items'
    ];
    
    const { getCases, getAllSemesters, getPrograms, updatePriorsAndProgram } = useDataService();

    const [cases, setCases] = useState([]);
    const [semesters, setSemesters] = useState([]);
    
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [filter, setFilter] = useState(false);
    const [activeSort, setSort] = useState({
        colIndex: 4,
        asc: false
    })

    // update sort criteria to point to specific column
    // if column index is same as before, just toggle sort direction
    const toggleSort = colIndex => {
        setSort({
            colIndex: colIndex,
            asc: colIndex === activeSort.colIndex ? !activeSort.asc : true
        })
    }

    const [programs, setPrograms] = useState([]);

    const [program, setProgram] = useState(null);
    const [priors, setPriors] = useState(holdItemsList[0]);

    const semesterForm = useFormik({
        initialValues: {
            semester: "Winter 2022"
        }
    });

    const filterForm = useFormik({
        initialValues: {
            caseID: "",
            studentName: "",
            studentNumber: "",
            studentEmail: "",
            programNA: false,
            priorsNA: false,
            programAssigned: false,
            priorsAssigned: false,
        }
    });

    useEffect(() => {
        setLoading(true);
        getAllSemesters()
        .then(semesters => setSemesters(semesters))
        .catch(err => console.log(err))
        .finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setEditing(false);
        getCases('/cases/priors', semesterForm.values.semester)
        .then(data => {
            setCases(data.map(obj => {
                obj.isEdited = false;
                obj.show = true;
                return obj;
            }));
            setSort({
                colIndex: 4,
                asc: false
            });
        })
        .catch(err => {
            console.log(err);
            setError(err.message ? err.message.toString() : error.toString());
        })
    }, [semesterForm.values.semester]);

    // re-filter the list every time filter criteria changes
    useEffect(() => {
        setCases(c => [...cases.map(filterRules)]);
    }, [filterForm.values])

    // re-sort the list every time sort criteria changes
    useEffect(() => {
        setCases([...cases.sort(comparator)]);
    }, [activeSort])


    const filterRules = (c) => {
        let show = false;
        // check caseID
        show = c.cases.filter(c => c.caseID.toString().includes(filterForm.values.caseID)).length > 0;

        //check student name
        if (show) {
            show = c.cases.filter(c => c.studentName?.toLowerCase().includes(filterForm.values.studentName.toLowerCase())).length > 0;
        }

        //check student Number
        if (show) {
            show = c.cases.filter(c => c.studentID ? c.studentID.toLowerCase().includes(filterForm.values.studentNumber.toLowerCase()) : true).length > 0;
        }

        //check student Email
        if (show) {
            show = c.cases.filter(c => c.studentEmail ? c.studentEmail.toLowerCase().includes(filterForm.values.studentEmail.toLowerCase()) : true).length > 0;
        }

        //check student program
        if (show && filterForm.values.programNA) {
            show = c.cases.filter(c => !c.programId).length > 0;
        }
        if (show && filterForm.values.programAssigned) {
            show = c.cases.filter(c => c.programId).length > 0;
        }

        //check student priors
        if (show && filterForm.values.priorsNA) {
            show = c.cases.filter(c => !c.priorHoldItems).length > 0;
        }
        if (show && filterForm.values.priorsAssigned) {
            show = c.cases.filter(c => c.priorHoldItems).length > 0;
        }
        

        c.show = show;
        return c;
    }

    // TODO: fix and make it so it goes through all subcases
    const comparator = (case1, case2) => {
        let res = 0;
        switch (tableHeaders[activeSort.colIndex]) {
            case 'Case #':
                res = case1.groupID - case2.groupID;
                break;
            case 'Student Name':
                res = case1.cases[0]?.studentName?.localeCompare(case2.cases[0].studentName);
                break;
            case 'Student Number':
                res = case1.cases[0]?.studentID?.localeCompare(case2.cases[0].studentID);
                break;
            case 'Student Email':
                res = case1.cases[0]?.studentEmail?.localeCompare(case2.cases[0].studentEmail);
                break;
            case 'Program':
                res = case1.cases.filter(c => !c.programId).length - case2.cases.filter(c => !c.programId).length;
                break;
            case 'Prior Hold Items':
                res = case1.cases.filter(c => !c.priorHoldItems).length - case2.cases.filter(c => !c.priorHoldItems).length;
                break;
            default: 
                res = 0;
        }

        if (activeSort.asc === false) {
            res = -res;
        }

        return res;
    }

    const edit = (subcase) => {
        setPrograms([]);
        getPrograms(subcase.departmentId)
        .then(data => {
            setPrograms(data);
            setProgram(subcase.programId ? subcase.programId : "-1");
            setPriors(subcase.priorHoldItems ? subcase.priorHoldItems : "-1");
            subcase.isEdited = true;    
            setEditing(true);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const cancel = (subcase) => {
        setProgram(null);
        setPriors(holdItemsList[0]);
        subcase.isEdited = false;
        setEditing(false);
    }

    const update = (subcase) => {
        updatePriorsAndProgram(subcase.caseID, {priorHoldItems: priors != '-1' ? priors : '', programId: program != -1 ? program : null})
        .then(data => {

            subcase.priorHoldItems = data.priorHoldItems;
            subcase.program = data.program;
            subcase.programId = data.programId;
            subcase.isEdited = false;
            setEditing(false);
        })
        .catch(err => {
            console.log(err);
        });
    }

    return (<Container className="w-75">
        {loading
        ? 
        <>
            <h3 className="mt-2 mb-3">Loading...</h3>
        </>
        : 
        error
        ? 
        <>
            <h3 className="mt-2 mb-3">Whoops... Error!</h3>
            <p>{error}</p>
        </>
        :
        <>
            <h3 className="mt-2 mb-3">Add Priors</h3>
            <Form noValidate id="semesterForm"/>
            <Row className="mb-2 justify-content-between">
                <Col md="auto">
                    <Button
                        variant={filter ? "secondary" : "outline-secondary"}
                        onClick={() => {setFilter(!filter); filterForm.resetForm();}}
                    >
                        <span>Filter</span>
                        <FontAwesomeIcon icon={faFilter}  className="ms-1"/>
                    </Button>
                </Col>
                <Col md="auto">
                    <Form.Select
                        form="semesterForm"
                        id="semester"
                        name="semester"
                        onChange={semesterForm.handleChange}
                        value={semesterForm.values.semester}
                    >
                        {semesters.map((s, i) => (
                            <option key={i} value={s.semester}>{s.semester}</option>
                        ))}
                    </Form.Select>
                </Col>

            </Row>
            <Form noValidate id="casesFilterForm"></Form>
            <Table bordered responsive>
                <colgroup>
                    <col style={{width: '90px'}}/>
                    <col style={{width: '130px'}}/>
                    <col style={{width: '145px'}}/>
                    <col />
                    <col style={{width: '130px'}}/>
                    <col style={{width: '300px'}}/>
                    <col style={{width: '200px'}}/>
                </colgroup>
                <thead>
                    <tr>
                        {tableHeaders.map((title, index) => {
                            return (
                            <th key={index} style={{verticalAlign: 'top'}}>
                                <SortableColumnHeader
                                    key={index}
                                    text={title}
                                    sorted={activeSort.colIndex === index}
                                    asc={activeSort.asc}
                                    index={index}
                                    toggleSort={toggleSort}
                                />
                                <ColumnFilter
                                    column={title}
                                    filter={filter}
                                    values={filterForm.values}
                                    handleChange={filterForm.handleChange}
                                />
                            </th>)
                        })}
                        <th style={{verticalAlign: 'top'}}>Edit</th>
                    </tr>
                </thead>
                {cases.filter(c => c.show).map((groupCase, index) => {
                    return (
                    <tbody key={index}>
                        <tr key={groupCase.groupID}>
                            <td colSpan={8} className="bold bg-light">{groupCase.groupID} - {groupCase.department}</td>
                        </tr>
                        {groupCase.cases.map((subCase)=> {
                            return(
                                <tr key={subCase.caseID}>
                                    <td>{subCase.caseID}</td>
                                    <td>{subCase.studentName}</td>
                                    <td>{subCase.studentID}</td>
                                    <td>{subCase.studentEmail}</td>
                                    {
                                        subCase.isEdited ?
                                        <>
                                            <td>
                                                <select className="form-select" onChange={e => setProgram(e.target.value)} value={program ? program : ''}>
                                                    <option key="-1" value="-1">-- N/A --</option>
                                                    {programs.map((prog, index) => {
                                                        return (<option value={prog.id} key={index}>{prog.code}</option>)
                                                    })}
                                                </select>    
                                            </td>
                                            <td>
                                                <select className="form-select" onChange={e => setPriors(e.target.value)} value={priors}>
                                                    <option key="-1" value="-1">-- Not assigned --</option>
                                                    {holdItemsList.map(holdItem => {
                                                        return (<option value={holdItem} key={holdItem}>{holdItem}</option>)
                                                    })}
                                                </select>    
                                            </td>
                                            <td>
                                                <Button variant="primary" className="mx-2" onClick={update.bind(this, subCase)}>Submit</Button>
                                                <Button variant="danger" className="mx-2" onClick={cancel.bind(this, subCase)}>Cancel</Button>
                                            </td>
                                        </>
                                        :
                                        <>
                                            <td>{subCase.program ? subCase.program : "Not assigned"}</td>
                                            <td>{subCase.priorHoldItems ? subCase.priorHoldItems : "Not assigned"}</td>
                                            <td>
                                            {!editing ?
                                                <Button variant="outline-primary" className="mx-2" onClick={edit.bind(this, subCase)}>Edit</Button>
                                                :
                                                <div style={{height: "38px"}}></div>
                                            }
                                            </td>
                                        </>
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                    );
                })}
                {cases.filter(c => c.show).length === 0 && 
                    <tbody>
                        <tr>
                            <td colSpan={tableHeaders.length + 1} className="text-center"><i>No incidents found</i></td>
                        </tr>
                    </tbody>
                }
            </Table>
        </>
        }
    </Container>);
}

export default AddPriors;