import { Button, Col, Container, Form, Row, Spinner, Table, Alert, Card } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from 'yup';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import BlobService from "../../services/BlobService";
import useDataService from "../../hooks/useDataService";
import AccessDenied from '../Errors/AccessDenied';
import NotFound from '../Errors/NotFound';
import CaseDetails from "../../models/CaseDetails";

function StudentResponse(props) {

  // no response yet  -> no current response, show form
  // response already submitted -> show current reponse, alert, and form
  // past due -> show only current response

  const params = useParams();
  const navigate = useNavigate();
  const { getStudentCaseById, updateStudentResponse } = useDataService();

  const [student, setStudent] = useState([]);
  const [show, setShow] = useState(false);
  const [responseAllowed, setResponseAllowed] = useState(false);

  const [loading, setLoading] = useState(true);
  const [forbidden, setForbidden] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const [error, setError] = useState("");


  useEffect(() => {
    setLoading(true);
    getStudentCaseById(params.id)
      .then(incident => {
        setLoading(false);
        setStudent(incident);
        setShow(incident.studentResponseMessage ? false : true)
        setResponseAllowed(incident.statusId < 2)
      })
      .catch(err => {
        setLoading(false);
        if (err.code === 'FORBIDDEN') {
          setForbidden(true);
        } else if (err.code === 'ER_NOT_FOUND') {
          setNotFound(true);
        }
      });
    setStudent([]);
  }, [params.id]);


  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(true);
    updateStudentResponse(values)
      .then(attachments => {
        return Promise.all(attachments.map(a => {
          const file = values.attachments.find(e => !e.id && e.name === a.originalFile);
          return BlobService.uploadFile(a, file);
        }));
      })
      .then(responses => {
        setSubmitting(false);
        navigate('/student/cases');
      })
      .catch(err => console.log(err))
  };


  const studentResponseSchema = yup.object().shape({
    studentResponseMessage: yup.string().required('Response message must be entered').max(1200),
    attachments: yup.array().of(yup.mixed())
  });

  const removeFile = (setFieldValue, values, index) => {
    let newFiles = values;
    newFiles.splice(index, 1);
    setFieldValue("attachments", newFiles);
  };

  if (loading) {
    return <i>Loading...</i>;
  }

  if (forbidden) {
    return <AccessDenied />;
  }

  if (notFound) {
    return <NotFound />;
  }

  return (
    <Container className="w-75">
      <h3 className="mt-2 mb-3">Response to Incident #{student.caseID}</h3>
      <Table bordered size="sm" className='table'>
        <thead>
          <tr>
            <th>Student Name</th>
            <th>Student Number</th>
            <th>Student Email</th>
            <th>Course</th>
            <th>Semester</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{student.studentName}</td>
            <td>{student.studentID}</td>
            <td>{student.studentEmail}</td>
            <td>{student.courseCode}</td>
            <td>{student.semester}</td>
          </tr>
        </tbody>
      </Table>
      <br></br>
      <Table bordered size="sm" className='table'>
        <thead>
          <tr>
            <th>Professor</th>
            <th>Assignment </th>
            <th>Date of Incident</th>
            <th>Case Filed on</th>
            <th>Student Response Deadline</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{student.submittedByProfessor}</td>
            <td>{student.assessmentName}</td>
            <td>{new Date(student.incidentDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</td>
            <td>{new Date(student.submitted_date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</td>
            <td>{new Date(student.studentresponsedeadline).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</td>
          </tr>
        </tbody>
      </Table>

      <div>
          <strong>Incident description:</strong> {student.incidentDescription}
      </div>

      <Alert className="mt-2" variant="primary">The Academic Integrity Committee has received the assessment specifications and a copy of your work from your professor.</Alert>



      <Card className="mt-4">
        <Card.Header>Your current response {student.studentResponseDate && <span>(Submitted on {new Date(student.studentResponseDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })})</span>}</Card.Header>
        <Card.Body>
          {student.studentResponseMessage &&
            <Card.Text>
              <h6>Response message</h6>
              <p>{student.studentResponseMessage}</p>
              {student.attachments.length > 0 && <h6>Your attachments</h6>}
              <ul>
                {student.attachments.map((f, i) => (
                  <li key={i}>
                    <a href={f.url} target="_blank">{f.file}</a>
                  </li>
                ))
                }
              </ul>
              {responseAllowed &&
                <div>
                  <p className='text-muted'>You can submit another response. The Committee will be able to see your previous responses.</p>
                  <Button onClick={() => setShow(true)} >
                    Update response
                  </Button>
                </div>
              }
            </Card.Text>
          }
          {!student.studentResponseMessage && <Card.Text>You have not submitted a response yet.</Card.Text>}
        </Card.Body>
      </Card>


      {/* {((student.studentResponseMessage != null) && (show == true) && (click == false)) && */}
      {responseAllowed && show && 
        <Row className="mt-4">
          <h5>Submit a new response</h5>
          <Formik
            initialValues={{
              caseID: params.id,
              studentResponseMessage: student.studentResponseMessage ? student.studentResponseMessage : "",
              attachments: []
            }}
            validationSchema={studentResponseSchema}
            onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              isSubmitting,
              values,
              errors
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="studentResponseMessage">
                  <Form.Label>Your response message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    placeholder="Enter your description of the situation"
                    onChange={handleChange}
                    value={values.studentResponseMessage}
                    isInvalid={!!errors.studentResponseMessage}
                  />
                  <Form.Control.Feedback type="invalid">{errors.studentResponseMessage}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="attachments" className="mt-4 mb-3">
                  <Form.Label>Attachments (optional)</Form.Label>
                  <ul>
                    {values.attachments.map((f, i) => (
                      <li key={i}>
                        {f.name}
                        <Button
                          variant="link"
                          onClick={() => removeFile(setFieldValue, values.attachments, i)}
                        >Remove</Button>
                      </li>
                    ))
                    }
                  </ul>
                  <Form.Control
                    type="file"
                    name="attachments"
                    value={``}
                    onChange={(event) => {
                      if (event.currentTarget.files[0]) {
                        const newFiles = values.attachments;
                        newFiles.push(event.currentTarget.files[0]);
                        setFieldValue("attachments", newFiles);
                      }
                    }}
                    isInvalid={!!errors.attachments}
                  />
                  <Form.Text>Please attach your evidence files</Form.Text>
                  <Form.Control.Feedback type="invalid">{errors.attachments}</Form.Control.Feedback>
                </Form.Group>

                <Row className="justify-content-end">
                  <Col md="auto">
                    <Button
                      className="mb-3"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ?
                        <span>Loading <Spinner as="span" animation="border" size="sm" role="status" /></span>
                        : 'Submit'
                      }
                    </Button>
                  </Col>
                </Row>

              </Form>
            )
            }
          </Formik>
        </Row>
      }

      {/* } */}

      {/* {((new Date() > new Date(student.studentresponsedeadline)) && (student.studentResponseMessage == null)) && <Formik
        initialValues={{
          caseID: params.id,
          studentResponseMessage: student.studentResponseMessage,
          attachments: student.attachments || []
        }}
        validationSchema={studentResponseSchema}
        onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          isSubmitting,
          values,
          errors
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="studentResponseMessage">
              <Form.Label>Your response message</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                placeholder="Enter your description of the situation"
                onChange={handleChange}
                disabled={true}
                isInvalid={!!errors.studentResponseMessage}
              />
              <Form.Control.Feedback type="invalid">{errors.studentResponseMessage}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="attachments" className="mt-4 mb-3">
              <Form.Label>Attachments (optional)</Form.Label>
              <ul>
                {values.attachments.map((f, i) => (
                  <li key={i}>
                    {f.name}
                    <Button
                      variant="link"
                      onClick={() => removeFile(setFieldValue, values.attachments, i)}
                    >Remove</Button>
                  </li>
                ))
                }
              </ul>
              <Form.Control
                type="file"
                name="attachments"
                value={``}
                disabled={true}
                onChange={(event) => {
                  if (event.currentTarget.files[0]) {
                    const newFiles = values.attachments;
                    newFiles.push(event.currentTarget.files[0]);
                    setFieldValue("attachments", newFiles);
                  }
                }}
                isInvalid={!!errors.attachments}
              />
              <Form.Text>Please attach your evidence files</Form.Text>
              <Form.Control.Feedback type="invalid">{errors.attachments}</Form.Control.Feedback>
            </Form.Group>

            <Row className="justify-content-center">
              <Link to="/student/cases">
                <Button
                  className="mb-3"
                >
                  Back to list
                </Button>
              </Link>
            </Row>
          </Form>
        )
        }
      </Formik>} */}
    </Container>
  );
}

export default StudentResponse;
