/**
 * Enum-like class of all possible roles
 */
class Role {
    static Professor = new Role('Professor');
    static CommitteeMember = new Role('Committee Member');
    static Student = new Role('Student');
    static DepartmentAdministrator = new Role('Department Administrator');
    static SystemAdministrator = new Role('System Administrator');
    static Delegate = new Role('Delegate');

    constructor(role) {
        this.role = role;
    }
}

export default Role;