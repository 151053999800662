function ColumnSizes({columns, ...props}) {
    return (
        <colgroup>
            {columns.map((c, i) => {
                switch(c) {
                    case 'Case #':
                    case 'Select Case':
                    case 'Letter type':
                        return <col key={i} style={{width: '90px'}}/>;
                    case 'Date Created':
                    case 'Student':
                    case 'Deadline':
                        <col key={i} style={{width: '200px'}}/>;
                    case 'Status':
                    case 'Professor':
                    case 'Course':
                    case 'Evidence':
                    case 'Assessment Specifications':
                        return <col key={i}/>
                    default:
                        return null;
                }
            })}
        </colgroup>
    )
}

export default ColumnSizes;