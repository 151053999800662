import { useContext, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import Layout from "./Layout";
import Login from "./Login/Login";
import LoginRedirect from "./Login/LoginRedirect";
import LogoutRedirect from "./Login/LogoutRedirect";

function Lobby() {
    const [sidebar, setSidebar] = useState(true);
    const showSidebar = () => setSidebar(!sidebar);

    const user = useContext(UserContext)[0];
    const handleLogout = () => {
        fetch(`${process.env.REACT_APP_API_URL}/logout`, {
            headers: { 'Authorization': `Bearer ${user.token}` },
            redirect: 'follow'
        })
            .then(response => {
                return response.json();
            })
            .then((body) => window.location.href = body.redirect)
            .catch(err => console.log(err));
    }

    return (
        <div>
            <Navbar
                fixed="top"
                expand={true}
                onToggle={() => setSidebar(!sidebar)}
                variant="dark"
                style={{
                    backgroundColor: "#D92A1C",
                }}
            >
                <Container fluid className="justify-content-between ms-3 me-3">
                    <span style={{width: "20%"}}>
                        {user && <button className="sidebar-btn" onClick={showSidebar}>≡</button>}
                    </span>
                    <Navbar.Brand>Academic Integrity Tracker</Navbar.Brand>
                    <Navbar.Collapse className="flex-grow-0 justify-content-center" style={{width: "20%"}}>
                        <Nav>
                            {user && 
                                <NavDropdown title={user.userData.email} id="user-actions" style={{color: "white"}}>
                                    <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/login/redirect" element={<LoginRedirect />} />
                <Route path="/logout/redirect" element={<LogoutRedirect />} />
                <Route path="*" element={
                    <Layout 
                        sidebar={sidebar}
                    />
                } />
            </Routes>

        </div>
    )
}

export default Lobby;