import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { UserContext } from "../../contexts/UserContext";
import useDataService from "../../hooks/useDataService";
import CasesList from "../CasesList/CasesList";
import DecisionLettersUpload from "./DecisionLettersUpload";

function DecisionLettersList(props) {
    const user = useContext(UserContext)[0];

    const columns = ['Select Case', 'Student', 'Professor', 'Letter type'];
    const endpoint = '/cases';
    const defaultSort = {
        colIndex: 1,
        asc: true
    };

    const [refresh , setRefresh] = useState(1);
    const refreshList = () => {
        setRefresh(refresh+1);
    }

    const caseSelection = useFormik({
        initialValues: {
            caseIDs: []
        }
    })
    
    const {getDecisionLetters, getCSV, sendLetters} = useDataService();

    const zipDownloadRef = useRef(null);
    const csvDownloadRef = useRef(null);
    const [gettingLetters, setGettingLetters] = useState();
    const [gettingCSV, setGettingCSV] = useState();
    const [sendingLetters, setSendingLetters] = useState();

    const [error, setError] = useState();
    
    const [uploadModal, setUploadModal] = useState(false);

    const handleGetLetters = () => {
        setError();
        setGettingLetters(true);
        getDecisionLetters(caseSelection.values)
        .then(cases => {
            const files = cases.map(c => ({url: c.attachments[0].url}));
            return fetch(process.env.REACT_APP_ZIP_URL, {
                method: 'POST',
                headers: {'x-functions-key': user.userData.zipFunctionKey},
                body: JSON.stringify(files)
            })
        })
        .then(res => res.blob())
        .then(blob => {
            let b = new Blob([blob]);
            let url = URL.createObjectURL(b);
            zipDownloadRef.current.href = url;
            zipDownloadRef.current.click();
            URL.revokeObjectURL(url);
            setGettingLetters(false);
        })
        .catch(err => {
            setGettingLetters(false);
            setError(err.message);
        });
    }

    const handleGetCSV = () => {
        setError();
        setGettingCSV(true);
        getCSV(caseSelection.values)
        .then(blob => {
            let b = new Blob([blob]);
            let url = URL.createObjectURL(b);
            csvDownloadRef.current.href = url;
            csvDownloadRef.current.click();
            URL.revokeObjectURL(url);
            setGettingCSV(false);
        })
        .catch(err => {
            setGettingCSV(false);
            setError(err.message)
        });
    }

    const handleUploadLetters = () => {
        setUploadModal(true);
    }

    const handleSend = () => {
        setError();
        setSendingLetters(true);
        sendLetters(caseSelection.values)
        .then(() => {
            setSendingLetters(false);
            refreshList();
            caseSelection.resetForm();
        })
        .catch(err => {
            setSendingLetters(false);
            setError(err.message ? err.message : "Error!")
        })
    }
    
    return (
        <div key={refresh}>
            <a href="/" ref={zipDownloadRef} download={`Decision_letters.zip`} className="d-none">Decision setters</a>
            <a href="/" ref={csvDownloadRef} download={`Decision_data.csv`} className="d-none">Decision data</a>
            <DecisionLettersUpload 
                show={uploadModal}
                handleHide={() => setUploadModal(false)}
                caseIDs={caseSelection.values.caseIDs}
                refreshList={refreshList}
            />
            <CasesList columns={columns} endpoint={endpoint} header="Closed Incidents (Decision Letters Pending)" defaultSort={defaultSort} selectionForm={caseSelection}/>
            <Container className="w-75">
                <Row>
                    <Col md="auto">
                        <Button
                            variant="primary"
                            onClick={handleGetLetters}
                            disabled={gettingLetters || caseSelection.values.caseIDs.length < 1}
                        >
                            {gettingLetters ? 
                                <span>Loading <Spinner as="span" animation="border" size="sm" role="status"/></span>
                                : 'Download decision letters'
                            }
                        </Button>
                    </Col>
                    <Col md="auto">
                        <Button
                            variant="primary"
                            onClick={handleGetCSV}
                            disabled={gettingCSV || caseSelection.values.caseIDs.length < 1}
                        >
                            {gettingCSV ? 
                                <span>Loading <Spinner as="span" animation="border" size="sm" role="status"/></span>
                                : 'Export CSV'
                            }
                        </Button>
                    </Col>
                    <Col md="auto">
                        <Button
                            variant="primary"
                            onClick={handleUploadLetters}
                            disabled={caseSelection.values.caseIDs.length < 1}
                        >
                            Replace letters
                        </Button>
                    </Col>
                    <Col md="auto">
                        <Button
                            variant="primary"
                            onClick={handleSend}
                            disabled={sendingLetters || caseSelection.values.caseIDs.length < 1}
                        >
                            {sendingLetters ? 
                                <span>Sending <Spinner as="span" animation="border" size="sm" role="status"/></span>
                                : 'Send letters'
                            }
                        </Button>
                    </Col>
                </Row>
                {error && <div className="invalid-feedback d-block">{error}</div>}
            </Container>
        </div>
    )
}

export default DecisionLettersList;