import CaseDetails from "./CaseDetails";

export default class Student {
    caseDetails = {
        incidentDescription: "",
        altIncidentDescription: ""
    };
    studentName = "";
    studentNumber = "";
    studentEmail = "";
}