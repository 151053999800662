import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Formik } from "formik";
import { Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import * as yup from 'yup';
import Student from "../../models/Student";
import IncidentDescriptionSelection from "./IncidentDescriptionSelection";

function StudentInfoStep(props) {

    const caseDetailsSchema = yup.object().shape({
        incidentDescription: yup.string().required('Incident description must be selected').notOneOf(['0'], "Invalid selection"),
        altIncidentDescription: yup.string()
            .when('incidentDescription', {
                is: 'Other',
                then: (schema) => schema.required('Other type of violation must be specified').max(500),
                otherwise: (schema) => schema.nullable()
            })
    });

    const studentInfoSchema = yup.object().shape({
        caseDetails: props.formData.groupCase ? caseDetailsSchema : yup.object().notRequired(),
        students: yup.array().of(yup.object({
            studentName: yup.string().required(`Student name must be entered`).max(100),
            studentNumber: yup.string().required(`Student number must be entered`).matches(/^\d{9}$/, "Must be a 9-digit string"),
            studentEmail: yup.string().required('Student email must be entered').matches(/^[a-zA-Z0-9._-]+$/, "Only alphanumeric characters accepted").max(100),
            caseDetails: props.formData.groupCase ? yup.object().notRequired() : caseDetailsSchema,
        })).min(props.formData.groupCase ? 2 : 1, 'At least ${min} students must be added')
    })

    return (
        <Formik
            initialValues={{
                caseDetails: props.formData.caseDetails,
                students: [...props.formData.students]
            }}
            validationSchema={studentInfoSchema}
            onSubmit={(values) => props.addStudentInfo(values)}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                errors
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    {props.formData.groupCase &&
                        <IncidentDescriptionSelection
                            target="caseDetails"
                            handleChange={handleChange}
                            values={values.caseDetails}
                            errors={errors.caseDetails}
                        />
                    }
                    <Row>
                        <Col className="invalid-feedback d-block mb-2">
                            {typeof errors.students === 'string' && errors.students}
                        </Col>
                    </Row>
                    <FieldArray
                        name="students"
                        validateOnChange={false}
                        render={arrayHelpers => (
                            <div>
                                {
                                    values.students.map((s, index) => (
                                        <Row key={index} className="pb-4 border-bottom">
                                            <Col md={11}>
                                                <Row className="mb-2 mt-3">
                                                    <Col md={4}>Student Name</Col>
                                                    <Col md={3}>Student Number</Col>
                                                    <Col md={5}>Student Email</Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col md={4}>
                                                        <Form.Control
                                                            name={`students[${index}].studentName`}
                                                            id={`students[${index}].studentName`}
                                                            type="text"
                                                            placeholder="Enter student's name"
                                                            value={s.studentName}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.students?.[index]?.studentName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors.students?.[index]?.studentName}</Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Control
                                                            name={`students[${index}].studentNumber`}
                                                            id={`students[${index}].studentNumber`}
                                                            type="text"
                                                            placeholder="Enter student's number"
                                                            value={s.studentNumber}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.students?.[index]?.studentNumber}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors.students?.[index]?.studentNumber}</Form.Control.Feedback>
                                                    </Col>

                                                    <Col md={5}>
                                                        <InputGroup>
                                                            <FormControl
                                                                aria-label="Student email"
                                                                aria-describedby="studentDomain"
                                                                name={`students[${index}].studentEmail`}
                                                                id={`students[${index}].studentEmail`}
                                                                type="text"
                                                                placeholder="Enter student's email"
                                                                value={s.studentEmail}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.students?.[index]?.studentEmail}
                                                            />
                                                            <InputGroup.Text id="studentDomain">{process.env.REACT_APP_STUDENT_DOMAIN}</InputGroup.Text>
                                                            <Form.Control.Feedback type="invalid">{errors.students?.[index]?.studentEmail}</Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                {!props.formData.groupCase &&
                                                    <IncidentDescriptionSelection
                                                        target={`students[${index}].caseDetails`}
                                                        handleChange={handleChange}
                                                        values={s.caseDetails}
                                                        errors={errors.students?.[index]?.caseDetails}
                                                    />
                                                }
                                            </Col>
                                            <Col md={1} className="align-self-center">
                                                <div style={{ textAlign: "center", fontSize: "1.5em" }}>
                                                    <FontAwesomeIcon icon={faTrashAlt} onClick={() => arrayHelpers.remove(index)} />
                                                </div>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                <Button
                                    variant="success"
                                    onClick={() => arrayHelpers.push(new Student())}
                                    className="mb-3 mt-2"
                                >
                                    <strong>+ </strong>Add student
                                </Button>
                            </div>
                        )}
                    />
                    <Row className="justify-content-space-between">
                        <Col>
                            <Button variant="outline-danger" onClick={() => props.previousStep()}>&lt; Back</Button>
                        </Col>
                        <Col className="text-end">
                            <Button type="submit" className="mb-3">Continue</Button>
                        </Col>
                    </Row>
                </Form>
            )
            }
        </Formik>
    )
}

export default StudentInfoStep;