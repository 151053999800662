import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from 'yup';
import useDataService from "../../hooks/useDataService";

function ProgramsList(props) {

    let params = useParams();
    const { getPrograms, addProgram } = useDataService();

    const [loading, setLoading] = useState(true);
    const [programs, setPrograms] = useState([]);
    const [error, setError] = useState('');
    const [postError, setPostError] = useState("");
    const [add, setAdd] = useState(false);

    const navigate = useNavigate();

    const programSchema = yup.object().shape({
        name: yup.string().required('Name is required').max(60),
        code: yup.string().required('Code must be selected').max(10)
    })

    const createProgram = (formData, setSubmitting, resetForm) => {
        setPostError();
        setSubmitting(true);
        addProgram(params.deptId, formData)
        .then(progs => {
            setPrograms(progs);
            resetForm();
            setAdd(false);
        })
        .catch(error => {
            console.log(error);
            setPostError(error.message.toString());
        }) 
        .finally(() => setSubmitting(false));
    }

    useEffect(() => {
        setLoading(true);
        getPrograms(params.deptId) //params.id
        .then(data => {
            setPrograms(data);
        })
        .catch(err => {
            setError(err.message.toString());
        })
        .finally(() => {
            setLoading(false);
        });
    }, [params.deptId]);

    return (
        <Container className="mt-4">
            { loading 
            ?
            <p>Loading...</p>
            : error == "" 
            ?
            <>
            <Row className="mb-3 justify-content-between">
                <Col>
                    <h3>Programs</h3>
                </Col>
                <Col md="auto">
                    <Button variant= {add ? 'secondary' : 'outline-primary'} onClick={()=>setAdd(!add)}>
                        <span>{add ? "Cancel" : "Add"}</span>
                    </Button>
                </Col>
            </Row>
            {add &&
            <div className="mb-4">
            <Formik
                initialValues={{
                    name: "",
                    code: ""
                }}
                validationSchema={programSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => createProgram(values, setSubmitting, resetForm)}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isSubmitting
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <h5 className="mt-4 mb-3">Add program</h5>
                        <Row>
                            <Col md={2}>
                                <Form.Group as={Col} controlId="name" label="User's name">
                                    <Form.Label>Program Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="code"
                                        placeholder="PRG"
                                        value={values.code}
                                        onChange={handleChange}
                                        isInvalid={!!errors.code}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group as={Col} controlId="name" label="User's name">
                                    <Form.Label>Program Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Program name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={2} className="align-self-end text-end">
                                <Button className="w-100" disabled={isSubmitting} type="submit">Add</Button>
                            </Col>
                        </Row>
                        <div className={`invalid-feedback ${postError ? 'd-block' : 'd-none'}`}>
                            {postError}
                        </div>
                    </Form>
                )}

            </Formik>
            </div>}
            <Table hover>
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {programs.map(p => (
                        <tr key={p.id} className="clickable" onClick={() => navigate(`/departments/${params.deptId}/programs/${p.id}`)}>
                            <td>{p.code}</td>
                            <td>{p.name}</td>
                        </tr>
                    ))}
                    {programs.length === 0 &&
                        <tr>
                            <td className="text-center" colSpan={2}><i>No Programs yet!</i></td>
                        </tr>
                    }
                </tbody>
            </Table>
            </>
            : <p>{error}</p>
            }
        </Container>
    )
}
export default ProgramsList;