import { Dropdown, Form } from "react-bootstrap";


function ColumnFilter(props) {

    switch (props.column) {
        case 'Case #':
            return (
                <Form.Control
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    type="text"
                    name="caseID"
                    id="caseID"
                    placeholder="Ex:123"
                    value={props.values.caseID}
                    onChange={props.handleChange}
                />
            );
        case 'Student Name':
            return (
                <Form.Control
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    type="text"
                    name="studentName"
                    id="studentName"
                    placeholder="Start typing"
                    value={props.values.studentName}
                    onChange={props.handleChange}
                />
            )
        case 'Student Number':
            return (
                <Form.Control
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    type="text"
                    name="studentNumber"
                    id="studentNumber"
                    placeholder="Start typing"
                    value={props.values.studentNumber}
                    onChange={props.handleChange}
                />
            );
        case 'Student Email':
            return (
                <Form.Control
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    type="text"
                    name="studentEmail"
                    id="studentEmail"
                    placeholder="Start typing"
                    value={props.values.studentEmail}
                    onChange={props.handleChange}
                />
            );
        case 'Program':
            return (
                <div key={props.filter}>
                    <Form.Check
                        form="casesFilterForm"
                        className={props.filter ? 'd-block' : 'd-none'}
                        type="checkbox"
                        name="programNA"
                        id="programNA"
                        label="N/A"
                        value={props.values.programNA}
                        onChange={props.handleChange}
                    />
                    <Form.Check
                        form="casesFilterForm"
                        className={props.filter ? 'd-block' : 'd-none'}
                        type="checkbox"
                        name="programAssigned"
                        id="programAssigned"
                        label="Assigned"
                        value={props.values.programAssigned}
                        onChange={props.handleChange}
                    />
                </div>
            );
        case 'Prior Hold Items':
            return (
                <div key={props.filter}>
                    <Form.Check
                        form="casesFilterForm"
                        className={props.filter ? 'd-block' : 'd-none'}
                        type="checkbox"
                        name="priorsNA"
                        id="priorsNA"
                        label="Not assigned"
                        value={props.values.priorsNA}
                        onChange={props.handleChange}
                    />
                    <Form.Check
                        form="casesFilterForm"
                        className={props.filter ? 'd-block' : 'd-none'}
                        type="checkbox"
                        name="priorsAssigned"
                        id="priorsAssigned"
                        label="Assigned"
                        value={props.values.priorsAssigned}
                        onChange={props.handleChange}
                    />
                </div>
            );
        default:
            return null;
    }
}

export default ColumnFilter;