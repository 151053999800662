import RolesContactsForm from "./RolesContactsForm";
import useDataService from "../../hooks/useDataService";

function RolesContactsManagement(props) {

    const DEPT_ROLES_OPTIONS = [
        'Committee Member',
        'Department Administrator'
    ];

    const DEPT_CONTACTS_OPTIONS = [
        'Department Chair', 
        'Registrar',
        'Other'
    ];

    const PROG_CONTACTS_OPTIONS = [
        'Program Coordinator', 
        'Student Advisor',
        'Other'
    ]

    const {
        getDepartmentRoles,
        assignDepartmentRole,
        removeDepartmentRole,
        getDepartmentContacts,
        assignDepartmentContact,
        removeDepartmentContact,
        getProgramContacts,
        assignProgramContact,
        removeProgramContact
    } = useDataService();

    switch(props.type) {
        case 'DEPT_ROLES':
            return (
                <RolesContactsForm
                    sectionHeader="Committee role assignments"
                    formHeader="Assign user role"
                    options={DEPT_ROLES_OPTIONS}
                    get={getDepartmentRoles}
                    create={assignDepartmentRole}
                    delete={removeDepartmentRole}
                    postErrorMessage="This user already has a role in this department"
                    emptyTableMessage="No roles have been assigned"
                    infoMessage="Users in this list have access to the application"
                />
            )
        case 'DEPT_CONTACTS':
            return (
                <RolesContactsForm
                    sectionHeader="Department mailing list"
                    formHeader="Add department contact"
                    options={DEPT_CONTACTS_OPTIONS}
                    get={getDepartmentContacts}
                    create={assignDepartmentContact}
                    delete={removeDepartmentContact}
                    postErrorMessage="This user already has a role in this department"
                    emptyTableMessage="No contacts have been added"
                    infoMessage="Users in this list do not have access to the application but will be notified about incidents"
                />
            )
        case 'PROG_CONTACTS':
            return (
                <RolesContactsForm
                    sectionHeader="Program mailing list"
                    formHeader="Add program contact"
                    options={PROG_CONTACTS_OPTIONS}
                    get={getProgramContacts}
                    create={assignProgramContact}
                    delete={removeProgramContact}
                    postErrorMessage="This user already has a role in this program"
                    emptyTableMessage="No contacts have been added"
                    infoMessage="Users in this list do not have access to the application but will be notified about incidents"
                />
            )
        default: 
                return null;
    }
}

export default RolesContactsManagement;