import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

const { Container, Col, Row, Button } = require("react-bootstrap");
function Login() {

    const user = useContext(UserContext)[0];

    if (user) return <Navigate to="/"/>;

    return (
        <Container fluid className="w-75">
            <Row className="min-vh-100 flex-column align-items-center justify-content-center">
                <Col md="auto" className="text-center">
                    <h2 className="mb-5">Log in with your Seneca account to access the app</h2>
                    <Button size="lg" variant="danger" href={`${process.env.REACT_APP_API_URL}/login`}>Login</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default Login;