import FormData from "../../models/FormData";
import CreateCaseForm from "./CreateCaseForm";

const { useState, useEffect } = require("react");
const { Container, Breadcrumb } = require("react-bootstrap");

function CreateCase() {

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(new FormData());
    const [responseData, setResponseData] = useState([]);

    const nextStep = () => {
        setStep(step + 1);
    }

    const previousStep = () => {
        setStep(step - 1);
    }

    const addFacultyInfo = facultyInfo => {
        formData.setFacultyInfo(facultyInfo);
        setFormData({ ...formData });
        nextStep();
    }

    const addStudentInfo = studentInfo => {
        formData.setStudents(studentInfo);
        setFormData({ ...formData });
        nextStep();
    }


    const confirmation = (data) => {
        setResponseData(data);
        nextStep();
    }


    return (
        <Container className="w-75">
            <h3 className="mt-2 mb-3">Report Incident</h3>
            <Breadcrumb>
                <Breadcrumb.Item active={step === 1}>Faculty Information</Breadcrumb.Item>
                <Breadcrumb.Item active={step === 2}>Student Information</Breadcrumb.Item>
                <Breadcrumb.Item active={step === 3}>Incident Summary</Breadcrumb.Item>
                <Breadcrumb.Item active={step === 4}>Confirmation</Breadcrumb.Item>
            </Breadcrumb>
            <CreateCaseForm
                step={step}
                formData={formData}
                addFacultyInfo={addFacultyInfo}
                addStudentInfo={addStudentInfo}
                previousStep={previousStep}
                confirmation={confirmation}
                responseData={responseData}
            />
        </Container>
    )
}

export default CreateCase;
