import { Accordion, Col, Container, Row } from "react-bootstrap";
import InfoHelp from "./InfoHelp";

function DepartmentInfo({ department, helpText, ...props }) {
    return (
        <Container>
            <Row>
                <Col md={3}>
                    <p>Short name: </p>
                </Col>
                <Col>
                    <p>{department.name}</p>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <p>Department Full Name: </p>
                </Col>
                <Col>
                    <p>{department.fullName}</p>
                </Col>
            </Row>
            <h5 className="mt-2 mb-3">Decision letter templates</h5>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <span className="me-4">"Guilty" letter template</span>
                        <InfoHelp message={helpText.guilty}/>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            <Col md={3}>
                                <p>"Guilty" preamble: </p>
                            </Col>
                            <Col>
                                <p style={{whiteSpace: "pre-line"}}>{department.guiltyPreamble}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <p>"Guilty" postamble: </p>
                            </Col>
                            <Col>
                                <p style={{whiteSpace: "pre-line"}}>{department.guiltyPostamble}</p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <span className="me-4">"Innocent" letter template</span>
                        <InfoHelp message={helpText.innocent} />
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            <Col md={3}>
                                <p>"Innocent" preamble: </p>
                            </Col>
                            <Col>
                                <p style={{whiteSpace: "pre-line"}}>{department.innocentPreamble}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <p>"Innocent" postamble: </p>
                            </Col>
                            <Col>
                                <p style={{whiteSpace: "pre-line"}}>{department.innocentPostamble}</p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <h5 className="mt-3 mb-3">AI assignments</h5>
            <Row className="mb-2">
                <Col md={2}><strong>Name</strong></Col>
                <Col md={10}><strong>Details</strong></Col>
            </Row>
            {department.assignments.map(a => (
                <Row key={a.id} className={`pt-2 mb-2 pb-3 border-bottom ${a.hidden ? 'bg-secondary bg-opacity-10' : ''}`}>
                    <Col md={2} className="d-flex flex-column justify-content-between">
                        {a.name}
                        {a.hidden && <p className="fst-italic">Hidden</p>}
                    </Col>
                    <Col md={10}>
                        <div className="d-flex flex-column mb-1">
                            <strong className="text-dark">Description: </strong>
                            {a.description}
                        </div>
                        <div className="d-flex flex-column">
                            <strong className="text-dark">Consequence: </strong>
                            {a.consequence ? `${a.consequence}` : 'N/A'}
                        </div>

                    </Col>
                </Row>
            ))}

        </Container>
    )
}

export default DepartmentInfo;