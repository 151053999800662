import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Formik } from "formik";
import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Image, ListGroup, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { openInNewTab, supportedImageExtensions } from "../../helpers/helpers";
import Evidence from "../../models/Evidence";
import * as yup from 'yup';
import BlobService from "../../services/BlobService";
import { useNavigate } from "react-router-dom";
import useDataService from "../../hooks/useDataService";

function EditCase(props) {
    const params = useParams();
    const [aiCase, setAiCase] = useState();
    const [error, setError] = useState();
    const navigate = useNavigate();
    const { getCaseById, updateCase } = useDataService();


    const caseSchema = yup.object().shape({
        caseID: yup.string().required(),
        groupCase: yup.bool().required(),
        specsFiles: yup.array(),
        evidence: yup.array().of(yup.object({
            file: yup.mixed().required('Evidence attachment is required').not([""]),
            description: yup.string().required('Evidence description must be entered').max(750),
            student: yup.string().nullable()
          }))
    });

    const removeFile = (setFieldValue, values, index) => {
        let newFiles = values;
        newFiles.splice(index, 1);
        setFieldValue("specsFiles", newFiles);
    }

    useEffect(() => {
        getCaseById(params.id)
        .then(data => setAiCase(data))
        .catch(error => console.log(error));
    }, [params]);

    const handleSubmit = (formData, setSubmitting) => {
        setSubmitting(true);
        updateCase(formData)
        .then(attachments => {
            return Promise.all(attachments.map(a => {
                let file;
                if (a.attachmentType === 'evidence') {
                    file = formData.evidence.find(e => !e.id && e.file.name === a.originalFile).file;
                } else if (a.attachmentType === 'assessmentSpecs') {
                    file = formData.specsFiles.find(e => !e.id && e.name === a.originalFile);
                }
                return BlobService.uploadFile(a, file);
            }));
        })
        .then(responses => {
            formData.evidence.forEach(e => URL.revokeObjectURL(e.tempUrl));
            setSubmitting(false);
            navigate('/faculty/cases');
        })
        .catch(err => {
            setSubmitting(false);
            setError(err.message);
            console.log(err)
        })
    }

    if (!aiCase) {   // need to return a standardized loading sign
        return null;
    } else {
        return (
            <Container className="w-75">
                <h3 className="mt-2 mb-3">Edit Academic Integrity Case #{aiCase.caseNum}</h3>
                <Card bg="light" className="mb-4 mt-4">
                    <Card.Header>You are currently editing this incident</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Card.Text>
                                    <strong>Course:</strong> {aiCase.courseCode} <br></br>
                                    <strong>Department:</strong> {aiCase.department.name} <br />
                                    <strong>Assessment:</strong> {aiCase.assessmentName} <br></br>
                                </Card.Text>
                            </Col>
                            <Col>
                                <Card.Text>
                                    <strong>Faculty Name:</strong> {aiCase.submittedByProfessor} <br></br>
                                    <strong>Faculty Email:</strong> {aiCase.facultyEmail} <br></br>
                                    <strong>Incident Date:</strong> {aiCase.dateOfIncident} <br></br>
                                </Card.Text>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <h4>Student(s) Involved</h4>
                <ListGroup as="ol" variant="flush" numbered>
                    {
                        aiCase.studentsInvolved.map((s, index) => (
                            <ListGroup.Item as="li" key={index} className="d-flex">
                                <div className="ms-2">
                                    <div className="fw-bold">{s.fullName}</div>
                                    <div>Student Number: {s.studentId}</div>
                                    <div>Student Email: {s.email}</div>
                                </div>

                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
                <h4 className="mt-2 mb-3">Incident Description</h4>
                <p>{aiCase.incidentDescription}</p>
                <Formik
                    initialValues={{
                        caseID: aiCase.caseNum,
                        groupCase: aiCase.studentsInvolved.length > 1,
                        specsFiles: aiCase.assessmentSpecs,
                        evidence: aiCase.evidence
                    }}
                    onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}
                    validationSchema={caseSchema}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        values,
                        errors,
                        isSubmitting
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="specsFiles" className="mt-4 mb-3">
                                <h4 className="mt-2 mb-3">Assessment specifications</h4>
                                <Form.Label className="d-none">Assessment specifications</Form.Label>
                                <ul>
                                    {values.specsFiles.map((f, i) => (
                                        <li key={i}>
                                            {f.name}
                                            {/* show link to file if it's already submitted */}
                                            {   f.id ?
                                                <Button
                                                    variant="link"
                                                    href={f.url}
                                                >View file</Button>
                                                :
                                                <Button
                                                    variant="link"
                                                    onClick={() => removeFile(setFieldValue, values.specsFiles, i)}
                                                >Remove</Button>
                                            } 
                                        </li>
                                    ))
                                    }
                                </ul>
                                <Form.Control
                                    type="file"
                                    name="specsFiles"
                                    value={``}
                                    onChange={(event) => {
                                        if (event.currentTarget.files[0]) {
                                            const newFiles = values.specsFiles;
                                            newFiles.push(event.currentTarget.files[0]);
                                            setFieldValue("specsFiles", newFiles);
                                        }
                                    }}
                                    isInvalid={!!errors.specsFiles}
                                />
                                <Form.Text>Please attach the original assessment specifications</Form.Text>
                                <Form.Control.Feedback type="invalid">{errors.specsFiles}</Form.Control.Feedback>
                            </Form.Group>
                            <h4 className="mt-2 mb-3">Evidence</h4>
                            <FieldArray
                                name="evidence"
                                validateOnChange={false}
                                render={arrayHelpers => (
                                    <div>
                                        {
                                            values.evidence.map((e, index) => (
                                                <Row key={index} className="mb-2">
                                                    <Col md={4}>
                                                        <Form.Group controlId={`evidence[${index}].file`} className="mb-3">
                                                            <Form.Label>Evidence File</Form.Label>
                                                            {
                                                                e.file ?
                                                                    <p className="fs-4">
                                                                        {e.file.name}
                                                                        {/* don't allow to remove the file if it's already submitted */}
                                                                        {!e.id &&
                                                                            <Button variant="link" onClick={() => {
                                                                                URL.revokeObjectURL(e.tempUrl)
                                                                                setFieldValue(`evidence[${index}].file`, null)
                                                                                setFieldValue(`evidence[${index}].tempUrl`, null)
                                                                            }}>Remove</Button>
                                                                        }
                                                                    </p>
                                                                    :
                                                                    <Form.Control
                                                                        type="file"
                                                                        name={`evidence[${index}].file`}
                                                                        onChange={(event) => {
                                                                            setFieldValue(`evidence[${index}].file`, event.currentTarget.files[0])
                                                                            if (event.currentTarget.files[0].type.startsWith("image/"))
                                                                                setFieldValue(`evidence[${index}].tempUrl`, URL.createObjectURL(event.currentTarget.files[0]));
                                                                        }}
                                                                        isInvalid={!!errors.evidence?.[index]?.file}
                                                                    />
                                                            }
                                                            <Form.Text>Please attach evidence file</Form.Text>
                                                            <Form.Control.Feedback type="invalid">{errors.evidence?.[index]?.file}</Form.Control.Feedback>
                                                        </Form.Group>
                                                        {values.groupCase && !e.id &&
                                                            <Form.Group controlId={`evidence[${index}].student`}>
                                                                <Form.Label>Associated student</Form.Label>
                                                                <Form.Select
                                                                    name={`evidence[${index}].student`}
                                                                    id={`evidence[${index}].student`}
                                                                    onChange={handleChange}
                                                                    value={values.evidence[index].student}
                                                                    isInvalid={!!errors.evidence?.[index]?.student}
                                                                >
                                                                    <option value="All Students">All students</option>
                                                                    {
                                                                        aiCase.studentsInvolved.map((s, i) => (
                                                                            <option value={s.fullName}>{s.fullName}</option>
                                                                        ))
                                                                    }
                                                                </Form.Select>
                                                                <Form.Text>This will be used in the attachment name</Form.Text>
                                                                <Form.Control.Feedback type="invalid">{errors.evidence?.[index]?.student}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        }
                                                    </Col>
                                                    <Col>
                                                        {/* display preview of the image from Azure if it's already uploaded */}
                                                        {
                                                            e.file && e.id && supportedImageExtensions.some(ext => e.file.name.endsWith(ext)) &&
                                                            <Image src={e.file.url} fluid className="mb-2" />
                                                        }
                                                        {/* display preview of the image with generated URL (for newly added images) */}
                                                        {
                                                            e.file && !e.id && e.tempUrl &&
                                                            <Image src={e.tempUrl} fluid className="mb-2"/>
                                                        }
                                                        {/* display link to attachment from Azure if it's not an image */}
                                                        {
                                                            e.file && e.id && !supportedImageExtensions.some(ext => e.file.name.endsWith(ext)) &&
                                                            <Button variant="link" onClick={() => openInNewTab(e.file.url)}>View</Button>
                                                        }
                                                        <Form.Group controlId={`evidence[${index}].description`} className="mb-3">
                                                            <Form.Label>Evidence Description</Form.Label>
                                                            <Form.Control as="textarea"
                                                                rows={7}
                                                                placeholder="Tell us how this attachment illustrates the violation"
                                                                aria-label="evidenceDescription"
                                                                name={`evidence[${index}].description`}
                                                                onChange={handleChange}
                                                                value={e.description}
                                                                isInvalid={!!errors.evidence?.[index]?.description}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.evidence?.[index]?.description}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                        {/* don't allow to delete the file if it's already submitted */}
                                                    <Col md={1} className="align-self-center">
                                                        {   !e.id &&
                                                            <div style={{ textAlign: "center", fontSize: "1.5em" }}>
                                                                <FontAwesomeIcon icon={faTrashAlt} onClick={() => {
                                                                    URL.revokeObjectURL(e.tempUrl);
                                                                    arrayHelpers.remove(index)
                                                                }} />
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                        <Button
                                            variant="success"
                                            onClick={() => arrayHelpers.push(new Evidence())}
                                            className="mb-3 mt-2"
                                        >
                                            <strong>+ </strong>Add evidence
                                        </Button>
                                    </div>
                                )}
                            />
                            <Button 
                                className="mb-3"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ?
                                <span>Loading <Spinner as="span" animation="border" size="sm" role="status"/></span>
                                : 'Update case'
                                }
                            </Button>
                            {error && <div className="invalid-feedback d-block">{error}</div>}
                        </Form>
                    )
                    }
                </Formik>
            </Container>
        )

    }
}

export default EditCase;