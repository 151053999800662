import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import * as yup from 'yup';
import { UserContext } from "../../contexts/UserContext";
import { getSemesterOptions } from "../../helpers/helpers";
import useDataService from "../../hooks/useDataService";

function FacultyInfoStep(props) {

    const [departments, setDepartments] = useState([]);
    const user = useContext(UserContext)[0];

    const { getDepartmentOptions } = useDataService();

    const facultyInfoShema = yup.object().shape({
        facultyName: yup.string().required("Faculty name must be entered").max(100),
        facultyEmail: yup.string().required("Faculty email must be entered").matches(/^[a-zA-Z0-9._-]+$/, "Only alphanumeric characters accepted").max(100),
        courseCode: yup.string().required("Course code must be entered").matches(/^[a-zA-z]{3}\d{3}([a-zA-Z]{3})?$/, "e.g. PRJ666NAA"),
        department: yup.number().required("Department must be selected").notOneOf([0], "Invalid selection"),
        incidentDate: yup.date().required("Incident date must be entered"),
        assessmentName: yup.string().required("Assessment name must be entered").max(50),
        semester: yup.string().required("Semester must be selected"),
        groupCase: yup.array()
    });

    useEffect(() => {
        getDepartmentOptions()
            .then(departments => setDepartments(departments))
            .catch(err => console.log(err));
    }, [])

    return (
        <Formik
            initialValues={{
                facultyName: props.formData.facultyName,
                facultyEmail: user.userData.email.split('@')[0],
                courseCode: props.formData.courseCode,
                department: props.formData.department,
                incidentDate: props.formData.incidentDate,
                assessmentName: props.formData.assessmentName,
                semester: props.formData.semester,
                groupCase: props.formData.groupCase ? ['on'] : []
            }}
            validationSchema={facultyInfoShema}
            onSubmit={(values) => props.addFacultyInfo(values)}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                errors
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="facultyName" className="mb-2">
                        <Form.Label>Faculty Name</Form.Label>
                        <Form.Control
                            name="facultyName"
                            type="text"
                            placeholder="Enter your name"
                            value={values.facultyName}
                            onChange={handleChange}
                            isInvalid={!!errors.facultyName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.facultyName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Label htmlFor="facultyEmail">Faculty email</Form.Label>
                    <InputGroup className="mb-2">
                        <FormControl
                            aria-label="Faculty email"
                            aria-describedby="facultyDomain"
                            id="facultyEmail"
                            name="facultyEmail"
                            type="text"
                            placeholder="Autofill?"
                            value={values.facultyEmail}
                            disabled={true}
                            onChange={handleChange}
                            isInvalid={!!errors.facultyEmail}

                        />
                        <InputGroup.Text id="facultyDomain">{process.env.REACT_APP_FACULTY_DOMAIN}</InputGroup.Text>
                        <Form.Control.Feedback type="invalid">{errors.facultyEmail}</Form.Control.Feedback>
                    </InputGroup>
                    <Row>
                        <Form.Group as={Col} controlId="courseCode" className="mb-2">
                            <Form.Label>Course code</Form.Label>
                            <Form.Control
                                name="courseCode"
                                type="text"
                                placeholder="e.g. PRJ666"
                                value={values.courseCode}
                                onChange={handleChange}
                                isInvalid={!!errors.courseCode}
                            />
                            <Form.Control.Feedback type="invalid">{errors.courseCode}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="department" className="mb-2">
                            <Form.Label>Department</Form.Label>
                            <Form.Select
                                name="department"
                                onChange={handleChange}
                                value={values.department}
                                isInvalid={!!errors.department}
                            >
                                <option key="0" value="0">-- Select department --</option>
                                {departments.map(d => (
                                    <option key={d.id} value={d.id}>{d.name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.department}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} controlId="incidentDate">
                            <Form.Label>Incident Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="incidentDate"
                                onChange={handleChange}
                                isInvalid={!!errors.incidentDate}
                                value={values.incidentDate}
                            />
                            <Form.Control.Feedback type="invalid">{errors.incidentDate}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="semester">
                            <Form.Label>Semester</Form.Label>
                            <Form.Select
                                name="semester"
                                onChange={handleChange}
                                value={values.semester}
                                isInvalid={!!errors.semester}
                            >
                                {getSemesterOptions().map(opt => (
                                    <option key={opt} value={opt}>{opt}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.semester}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Form.Group controlId="assessmentName" className="mb-2">
                        <Form.Label>Assessment Name</Form.Label>
                        <Form.Control
                            name="assessmentName"
                            type="text"
                            placeholder="e.g. Assignment 1"
                            value={values.assessmentName}
                            onChange={handleChange}
                            isInvalid={!!errors.assessmentName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.assessmentName}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="groupCase">
                        <Form.Label>Linked cases</Form.Label>
                        <Form.Check
                            name="groupCase"
                            type="checkbox"
                            checked={values.groupCase[0] === 'on'}
                            onChange={handleChange}
                            label="This case involves multiple students"
                        />
                        <Form.Text>Check this box if cases you are going to report should be linked together</Form.Text>
                    </Form.Group>
                    <Row className="justify-content-end">
                        <Col className="flex-grow-0">
                            <Button type="submit">Continue</Button>
                        </Col>
                    </Row>
                </Form>
            )
            }
        </Formik>
    )
}

export default FacultyInfoStep;