import { useState } from "react";
import { Alert, Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import useDataService from '../../hooks/useDataService';

function IncidentSummaryStep(props) {

    const {createCase} = useDataService();
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = () => {
        setSubmitting(true);
        createCase(props.formData)
        .then(data => {
            props.confirmation(data);
        })
        .catch(error => {
            console.log(error)
            setSubmitting(false)
        })
    }

    return (
        <Container>
            {props.formData.groupCase &&
                <Alert variant="primary">Incident details and evidence will be applied to <strong><u>all students</u></strong> involved.</Alert>
            }
            <Table bordered>
                <thead>
                    <tr>
                        <th>Student name</th>
                        <th>Student number</th>
                        <th>Student email</th>
                        <th style={{maxWidth: "180px"}}>Incident description</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.formData.students.map((s, index) => (
                            <tr key={index}>
                                <td>{s.studentName}</td>
                                <td>{s.studentNumber}</td>
                                <td>{s.studentEmail}{process.env.REACT_APP_STUDENT_DOMAIN}</td>
                                {/* render 1 button to add case specs if it's a group case */}
                                {props.formData.groupCase && index === 0 &&
                                    <td 
                                        className="text-center" 
                                        rowSpan={0}
                                        style={{maxWidth: "180px", wordBreak: "break-word"}}
                                    >
                                        {props.formData.caseDetails.incidentDescription === 'Other' ?
                                            props.formData.caseDetails.altIncidentDescription
                                            : props.formData.caseDetails.incidentDescription
                                        }
                                    </td>
                                }
                                {/* render a button for each student in case it's NOT a group case */}
                                {!props.formData.groupCase &&
                                    <td 
                                        className="text-center"
                                        style={{maxWidth: "180px", wordBreak: "break-word"}}
                                    >
                                        {s.caseDetails.incidentDescription === 'Other' ?
                                            s.caseDetails.altIncidentDescription
                                            : s.caseDetails.incidentDescription
                                        }
                                    </td>
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <Row className="justify-content-space-between">
                <Col>
                    <Button
                        variant="outline-danger"
                        onClick={() => props.previousStep()}
                        disabled={submitting}
                    >&lt; Back</Button>
                </Col>
                <Col className="text-end">
                    <Button 
                        className="mb-3" 
                        onClick={handleSubmit}
                        disabled={submitting}
                    >
                        {submitting ?
                            <span>Loading <Spinner as="span" animation="border" size="sm" role="status"/></span>
                            : 'Report incident and notify students'
                        }
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

export default IncidentSummaryStep;
