import { Dropdown, Form } from "react-bootstrap";


function ColumnFilter(props) {


    switch (props.column) {
        case 'Case #':
            return (
                <Form.Control
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    type="text"
                    name="caseID"
                    id="caseID"
                    placeholder="Start typing"
                    value={props.values.caseID}
                    onChange={props.handleChange}
                />
            )
        case 'Select Case':
            return (
                <Form.Control
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    type="text"
                    name="caseID"
                    id="caseID"
                    placeholder="Start typing"
                    value={props.values.caseID}
                    onChange={props.handleChange}
                />
            )
        case 'Status':
            return (
                <Dropdown 
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    autoClose="outside"
                >
                    <Dropdown.Toggle style={{ width: "100%" }}>
                        {props.values.status.length === 0 && 'Status'}
                        {props.values.status.length > 0 && props.values.status.length < props.statuses.length 
                            && `${props.values.status.length} selected`
                        }
                        {props.values.status.length === props.statuses.length && 'All'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {props.statuses.map((e, i) => (
                            <Dropdown.Item as="div" key={e.id}>
                                <Form.Check
                                    type="checkbox"
                                    name="status"
                                    id={`${e.id}status`}
                                    onChange={props.handleChange}
                                    label={e.status}
                                    value={e.id} />
                            </Dropdown.Item>

                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )
        case 'Date Created':
            return (
                <div 
                    className={props.filter ? 'd-block' : 'd-none'}
                    style={{
                        fontWeight: 'normal',
                        textAlign: 'center'
                    }}
                >
                    <Form.Control
                        form="casesFilterForm"
                        size="sm"
                        type="date"
                        name="startDate"
                        id="startDate"
                        value={props.values.startDate}
                        onChange={props.handleChange}
                    />
                    <span>to</span>
                    <Form.Control
                        form="casesFilterForm"
                        size="sm"
                        type="date"
                        name="endDate"
                        id="endDate"
                        value={props.values.endDate}
                        onChange={props.handleChange}
                    />
                </div>
            )
        case 'Student':
            return (
                <Form.Control
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    type="text"
                    name="studentName"
                    id="studentName"
                    placeholder="Start typing"
                    value={props.values.studentName}
                    onChange={props.handleChange}
                />
            )
        case 'Professor':
            return (
                <Form.Control
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    type="text"
                    name="submittedByProfessor"
                    id="submittedByProfessor"
                    placeholder="Start typing"
                    value={props.values.submittedByProfessor}
                    onChange={props.handleChange}
                />
            )
        case 'Course':
            return (
                <Form.Control
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    type="text"
                    name="courseCode"
                    id="courseCode"
                    placeholder="Start typing"
                    value={props.values.courseCode}
                    onChange={props.handleChange}
                />
            )
        case 'Evidence':
            return (
                <Form.Select
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    name="evidence"
                    id="evidence"
                    value={props.values.evidence}
                    onChange={props.handleChange}
                >
                    <option value="all">All</option>
                    <option value="y">Submitted</option>
                    <option value="n">Not submitted</option>
                </Form.Select>
            )
        case 'Assessment Specifications':
            return (
                <Form.Select
                    form="casesFilterForm"
                    className={props.filter ? 'd-block' : 'd-none'}
                    name="specs"
                    id="specs"
                    value={props.values.specs}
                    onChange={props.handleChange}
                >
                    <option value="all">All</option>
                    <option value="y">Submitted</option>
                    <option value="n">Not submitted</option>
                </Form.Select>
            )
        default:
            return null;
    }
}

export default ColumnFilter;