
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Container, Form, Modal, Spinner, Table } from 'react-bootstrap';
import * as yup from 'yup';
import useDataService from '../../hooks/useDataService';
import BlobService from '../../services/BlobService';

function DecisionLettersUpload({ show, handleHide, caseIDs, refreshList, ...props }) {
    const schema = yup.object().shape({
        letters: yup.array().of(yup.mixed()).required().min(1)
    })

    const lettersForm = useFormik({
        initialValues: {
            letters: []
        },
        validationSchema: schema,
        onSubmit: (values) => handleSubmit(values)
    })

    const {updateDecisionLetters} = useDataService();

    const [caseLettersMapping, setCaseLettersMapping] = useState({
        matched: caseIDs.map(id => ({caseID: id, letter: 'None', file: undefined})),
        notMatched: []
    });
    const [error, setError] = useState();

    useEffect(() => {
        setCaseLettersMapping({
            matched: caseIDs.map(id => ({caseID: id, letter: 'None', file: undefined})),
            notMatched: []
        })
    }, [caseIDs]);

    useEffect(() => {
        const letterNames = lettersForm.values.letters.map(file => ({
            name: file.name,
            id: file.name.split(".", 1)[0].split("_").pop(),
            file: file
        }))
        const newMapping = caseLettersMapping.matched.map(entry => {
            let i = letterNames.findIndex(l => l.id == entry.caseID);
            entry.letter = i > -1 ? letterNames[i].name : 'None';
            entry.file = i > -1 ? letterNames[i].file : undefined;
            if (i > -1) 
                letterNames.splice(i, 1);
            return entry;
        })

        setCaseLettersMapping({
            matched: newMapping,
            notMatched: letterNames.map(l => l.name)
        })
    }, [lettersForm.values.letters])

    const handleSubmit = (values) => {
        if (caseLettersMapping.matched.filter(l => l.letter === 'None').length > 0) {
            lettersForm.setFieldError("letters", "Attached letters do not cover all cases in the list. Please try again");
            lettersForm.setSubmitting(false)
        } else {
            updateDecisionLetters({letters: caseLettersMapping.matched})
            .then(letters => {
                return Promise.all(letters.map(l => {
                    return BlobService.uploadFile(l, caseLettersMapping.matched.find(entry => entry.caseID === l.caseId).file);
                }));
            })
            .then(responses => {
                lettersForm.setSubmitting(false);
                refreshList();
                handleHide();
            })
            .catch(err => {
                lettersForm.setSubmitting(false);
                setError(err.message.toString());
            })
        }
    }

    return (
        <Modal show={show} onHide={handleHide} onEnter={() => {lettersForm.resetForm();setError();}} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Upload custom decision letters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form noValidate onSubmit={lettersForm.handleSubmit} id="letters-form">
                        <Form.Group controlId="letters">
                            <Form.Label>Select letters for upload</Form.Label>
                            <Form.Control
                                type="file"
                                multiple
                                name="letters"
                                onChange={event => lettersForm.setFieldValue("letters", Array.from(event.currentTarget.files), true)}
                                isInvalid={!!lettersForm.errors.letters}
                            />
                            <Form.Text>Attach custom decision letters named as Decision_letter_caseID.pdf</Form.Text>
                            <Form.Control.Feedback type="invalid">{lettersForm.errors.letters}</Form.Control.Feedback>

                        </Form.Group>
                    </Form>
                    <h5 className="mt-3">The following letters have been matched:</h5>
                    <Table striped bordered size="sm">
                        <thead>
                            <tr>
                                <th>Case #</th>
                                <th>Decision Letter</th>
                            </tr>
                        </thead>
                        <tbody>
                            {caseLettersMapping.matched.map(c => (
                                <tr key={c.caseID}>
                                    <td>{c.caseID}</td>
                                    <td>{c.letter}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {caseLettersMapping.notMatched.length > 0 && 
                        <div>
                            <h5 className="mt-3">The following letters <u>could not be matched</u>:</h5>
                            <ul>
                                {caseLettersMapping.notMatched.map((n, i) => (
                                    <li key={i}>{n}</li>
                                ))
                                }
                            </ul>
                        </div>
                    }
                    {error && <div className="invalid-feedback d-block">{error}</div>}
                </Container>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleHide}>Cancel</Button>
                    <Button
                        variant="primary"
                        type="submit"
                        form="letters-form"
                        disabled={lettersForm.isSubmitting}
                    >
                        {lettersForm.isSubmitting ?
                            <span>Loading <Spinner as="span" animation="border" size="sm" role="status" /></span>
                            : 'Upload letters'
                        }
                    </Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}

export default DecisionLettersUpload;