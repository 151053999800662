import { useContext, useEffect } from "react";
import { useState } from "react";
import { UserContext } from "../contexts/UserContext";

/**
 * Loads the JWT token from session storage into context,
 * validates it and stores user's details in the context
 * @returns state variable which is `true` when the token is valid
 */
function useToken() {
    const [valid, setValid] = useState();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useContext(UserContext);

    useEffect(() => {
        const token = sessionStorage.getItem("jwt_token");
        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/whoami`, {
                    headers: { 'Authorization': `Bearer ${token}` }
            })
            .then(response => response.json())
            .then(data => {
                 setUser({
                    token: token,
                    userData: data
                });
                sessionStorage.setItem("jwt_token", token);
                setValid(true);
                setLoading(false);
            })
            .catch(err => console.log(err));
        } else {
            setValid(false);
            setLoading(false);
        }
    }, [])

    return [loading, valid];
}

export default useToken;