import './Decision.css';
import { Badge, Button, Col, Container, Form, ListGroup, Row, Spinner, Modal } from "react-bootstrap";
import { useContext, useEffect, useState } from 'react';
import useDataService from '../../../hooks/useDataService';
import { UserContext } from '../../../contexts/UserContext';

function DecisionView({ caseID, poll, setEnteringDecisions, refresh, origStatus, confirmationsRequired, delegated, ...props }) {
    const user = useContext(UserContext)[0];
    const [decisions, setDecisions] = useState([]);
    const { getDecisions } = useDataService();

    useEffect(() => {
        getDecisions(caseID)
        .then(decisions => setDecisions(decisions))
        .catch(err => console.log(err))
        let pollTimer;
        if (poll) {
            pollTimer = setInterval(() => {
                getDecisions(caseID)
                .then(decisions => setDecisions(decisions))
                .catch(err => console.log(err))
            }, 5000);
        }
        
        return () => {
            clearInterval(pollTimer);
        }
    }, [])

    useEffect(() => {

        if (origStatus > 2 && decisions.length > 0 && decisions[0]?.statusId !== origStatus) {
            refresh();
        }
    }, [decisions])

    if (decisions.length === 0) {
        return null;
    }

    return (

        <Container className="case-decision">
            <h4>Decision(s)</h4>
            <ListGroup as="ol" variant="flush" numbered >
                {decisions.map((d, i) => (
                    <ListGroup.Item as="li" key={d.studentID} className="d-flex flex-wrap">
                        <Row className="ms-2 mb-2" style={{ width: "90%" }}>
                            <Col md={8} className="d-flex flex-column">
                                <span><strong>{d.studentName}</strong></span>
                                <span>{d.studentID}</span>
                                <span>{d.studentEmail}</span>
                            </Col>
                            <Col md={3} className="align-self-center">
                                <h4>
                                    {d.violation_occured === null && d.statusId < 3 &&
                                        <Badge bg="secondary">
                                            No decision yet
                                        </Badge>
                                    }
                                    {d.statusId === 3 &&
                                        <Badge bg="warning">
                                            In progress
                                        </Badge>
                                    }
                                    {d.statusId > 3 &&
                                        <Badge bg="success">
                                            Complete
                                        </Badge>
                                    }
                                    
                                </h4>
                            </Col>
                        </Row>
                        {d.statusId > 2 &&
                            <>
                                <Row className="w-100 ms-2 mb-2">
                                    <Col md={4}>
                                        <p className="mb-1">
                                            <strong>Decision: </strong>
                                            {d.violation_occured === null && 'Not Entered'}
                                            {d.violation_occured === true && 'Guilty'}
                                            {d.violation_occured === false &&'Not Guilty'}
                                        </p>
                                        <p className="mb-1"><strong>Assignment: </strong>{d.assignment ? d.assignment.name : 'None'}</p>
                                    </Col>
                                    <Col md={8}>
                                        <p className="mb-1"><strong>Sanctions: </strong>{d.sanctions ? d.sanctions : 'Not Entered'}</p>
                                        <p className="mb-1"><strong>Hold Items: </strong>{d.holdItems ? d.holdItems : 'Not Entered'}</p>
                                        <p className="mb-1"><strong>Incident Type: </strong>{d.incidentType ? d.incidentType : 'Not Entered'}</p>
                                    </Col>
                                </Row>
                                <Row className="w-100 ms-2">
                                    <Col md={6}>
                                        <span><strong>Comments:</strong></span>
                                        <p>{d.comments ? d.comments : 'Not Entered'}</p>
                                    </Col>
                                    <Col md={6}>
                                        <span><strong>Message to Student:</strong></span>
                                        <p>{d.messageToStudent ? d.messageToStudent : 'Not Entered'}</p>
                                    </Col>
                                </Row>
                            </>
                        }
                    </ListGroup.Item>
                ))}
            </ListGroup>
            {!delegated && 
            <DecisionControls 
                caseID={caseID}
                decisions={decisions}
                setEnteringDecisions={setEnteringDecisions}
                setDecisions={setDecisions}
                refresh={refresh}
                confirmationsRequired={confirmationsRequired}
            />
            }
            <div style={{ minHeight: "25vh" }} />
        </Container>
    )
}

function DecisionControls({ caseID, decisions, setEnteringDecisions, setDecisions, refresh, confirmationsRequired, ...props }) {
    const { lockDecisions, confirmDecision, exitDecisions, closeCase } = useDataService();

    const user = useContext(UserContext)[0];

    const [confirmed, setConfirmed] = useState(false);

    // manages confirmation modal state
    const [showModal, setShowModal] = useState(false);

    // request progress
    const [starting, setStarting] = useState(false);
    const [cancelling, setCancelling] = useState(false);
    const [closingCase, setClosingCase] = useState(false);
    const [confirming, setConfirming] = useState(false);

    // errors
    const [startError, setStartError] = useState();
    const [confirmError, setConfirmError] = useState();
    const [cancelError, setCancelError] = useState();
    const [closeError, setCloseError] = useState();

    useEffect(() => {
        setConfirmed(decisions[0]?.confirmations.includes(user.userData.email))
        setStartError();
    }, [decisions])

    const handleStart = () => {
        setStarting(true);
        lockDecisions(caseID)
        .then(response => {
            setStarting(false);
            setEnteringDecisions(true);
            refresh();
        })
        .catch(error => {
            console.log(error)
            setStarting(false);
            if (error.code === 'ER_LOCKED') {
                setStartError(error.message);
            }
        })
    }

    const handleConfirm = () => {
        setConfirming(true);
        confirmDecision(caseID)
            .then(response => {
                setConfirming(false);
                setConfirmed(true)
            })
            .catch(err => {
                setConfirming(false);
                if (err.code === 'ER_DUP_ENTRY') {
                    setConfirmError("You have already confirmed this decision");
                }
            })
    }

    const handleCancel = () => {
        setCancelling(true);
        exitDecisions(caseID)
        .then(newDecisions => {
            setCancelling(false);
            setDecisions(newDecisions)
        })
        .catch(error => {
            setCancelling(false);
            if (error.code === 'ER_LOCKED') {
                setCancelError(error.message)
            }
            console.log(error);
        })
    }

    const handleClose = () => {
        setShowModal(false);
        setCloseError();
        setClosingCase(true);
        closeCase(caseID)
        .then(response => setClosingCase(false))
        .catch(error => {
            setClosingCase(false)
            setCloseError(error.message);
        })
    }

    if (decisions[0]?.statusId < 3) {
        // decision hasn't been entered yet
        return (
            <Row className="justify-content-end">
                <Col md="auto">
                    <Button
                        onClick={handleStart}
                        disabled={starting}
                    >
                        {starting ?
                            <span>Loading <Spinner as="span" animation="border" size="sm" role="status"/></span>
                            : 'Enter decision(s)'
                        }
                    </Button>
                </Col>
                <div className="invalid-feedback d-block">{startError}</div>
            </Row>
        )
    } else if (decisions[0]?.statusId === 3 && user.userData.email === decisions[0]?.decisionLockedBy) {
        // decision has been entered and is locked by current user
        return (
            <Row className="justify-content-between align-items-center">
                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Case cannot be reopened after is it closed. Click <strong>Confirm</strong> to close this case.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                        <Button variant="primary" onClick={handleClose}>Confirm</Button>
                    </Modal.Footer>
                </Modal>
                <Col md={3} className="align-self-end">
                    <Button 
                        variant="outline-danger"
                        onClick={handleCancel}
                        disabled={cancelling}
                    >
                        {cancelling ?
                            <span>Loading <Spinner as="span" animation="border" size="sm" role="status"/></span>
                            : 'Cancel & Exit'
                        }
                    </Button>
                </Col>
                <Col md={9}>
                    <Row>
                        <Col md={12} className="mb-2">
                            <h4>
                                <Badge bg="light" text="dark">
                                    <span>Confirmations received: </span>
                                        <Badge pill bg={decisions[0]?.confirmations.length < confirmationsRequired-1 ? 'danger' : 'success'}>
                                            {decisions[0]?.confirmations.length}/{confirmationsRequired-1}
                                        </Badge>
                                </Badge>

                            </h4>
                        </Col>
                    </Row>
                    <Row className="justify-content-end">
                        <Col md="auto">
                            <Button variant="secondary" onClick={() => setEnteringDecisions(true)}>Edit decision(s)</Button>
                        </Col>
                        <Col md="auto">
                            <Button 
                                variant="primary"
                                onClick={() => setShowModal(true)}
                                disabled={closingCase || decisions[0]?.confirmations.length < confirmationsRequired-1}
                            >
                                {closingCase ?
                                    <span>Loading <Spinner as="span" animation="border" size="sm" role="status"/></span>
                                    : 'Close case'
                                }
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <div className="invalid-feedback d-block">{cancelError}</div>
                <div className="invalid-feedback d-block">{closeError}</div>
            </Row>
        )
    } else if (decisions[0]?.statusId === 3 && decisions[0]?.violation_occured && user.userData.email !== decisions[0]?.decisionLockedBy && !confirmed) {
        // decision has been entered and is locked by someone else - current user can only confirm (if not yet)
        return (
            <Row className="justify-content-end">
                <Col md="auto">
                    <Button
                        variant="success"
                        onClick={handleConfirm}
                        disabled={confirming}
                    >
                        {confirming ?
                            <span>Loading <Spinner as="span" animation="border" size="sm" role="status"/></span>
                            : 'Confirm decision'
                        }
                    </Button>
                </Col>
                <div className="invalid-feedback d-block">{confirmError}</div>
            </Row>
        )
    } else if (confirmed) {
        return (
            <Row className="justify-content-center">
                <Col md="auto">
                    <p>You have confirmed this decision</p>
                </Col>
            </Row>
        )
    } else {
        return null;
    }
}

export default DecisionView;