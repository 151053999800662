import { faExclamationTriangle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { Button, Card, Col, Container, ListGroup, Row, Spinner, Table, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { openInNewTab } from '../../helpers/helpers';
import useDataService from '../../hooks/useDataService';
import AccessDenied from '../Errors/AccessDenied';
import NotFound from '../Errors/NotFound';
import './ViewCase.css';
import DelegateReviewerForm from '../DelegateReviewer/DelegateReviewerForm';
import { UserContext } from '../../contexts/UserContext';
import DecisionView from './CaseDecision/DecisionView';
import DecisionEntry from './CaseDecision/DecisionEntry';

function ViewCase(props) {

    const user = useContext(UserContext)[0];

    let params = useParams();
    const { getCaseById } = useDataService();

    const [delegateModal, setDelegateModal] = useState(false);

    // keep a ref to the hidden <a> to click it for zip download
    const zipAnchorRef = useRef(null);
    const [waitingForZip, setWaitingForZip] = useState(false);

    const [showDelegateBtn, setShowDelegateBtn] = useState(true);

    const [aiCase, setAiCase] = useState(null);
    const [loading, setLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [notFound, setNotFound] = useState(false);

    const [dummy, setDummy] = useState(false);  // used for refreshing the page once case gets closed

    // controls switching between decision view & edit
    const [enteringDecisions, setEnteringDecisions] = useState(false);

    useEffect(() => {
        setForbidden(false);
        setNotFound(false);
        setLoading(true);
        setAiCase(null);
        getCaseById(params.id)
            .then(data => {
                // this user is a delegate reviewer if their email <> case faculty email and they don't have a role in this department
                const asDelegate = !(user.userData.email === data.submittedByProfessor || user.userData.roles.find(r => r.departmentId === data.department.id))
                setShowDelegateBtn(!asDelegate); // show the 'Assign Delegate' button only to non-delegates
                setAiCase(data)
            })
            .catch(err => {
                if (err.code === 'FORBIDDEN') {
                    setForbidden(true);
                } else if (err.code === 'ER_NOT_FOUND') {
                    setNotFound(true);
                }
            })
            .finally(() => setLoading(false));
    }, [params, dummy]);

    const downloadZip = () => {
        setWaitingForZip(true);
        let files = aiCase.assessmentSpecs.map(e => ({ url: e.url }));
        files = files.concat(aiCase.evidence.map(e => ({ url: e.file.url, desc: e.description })));
        const allStudentFiles = aiCase.studentsInvolved
            .map(s => {
                let studentFiles = s.responseAttachments.concat(s.responseForms);
                studentFiles.push(s.reportFile);
                if (s.decisionFile)
                    studentFiles.push(s.decisionFile);
                return studentFiles;
            })
            .flat()
            .map(f => ({ url: f.url }));
        files = files.concat(allStudentFiles);
        fetch(process.env.REACT_APP_ZIP_URL, {
            method: 'POST',
            headers: { 'x-functions-key': user.userData.zipFunctionKey },
            body: JSON.stringify(files)
        })
            .then(res => res.blob())
            .then(blob => {
                let b = new Blob([blob]);
                let url = URL.createObjectURL(b);
                zipAnchorRef.current.href = url;
                zipAnchorRef.current.click();
                URL.revokeObjectURL(url);
            })
            .catch(err => console.log(err))
            .finally(() => setWaitingForZip(false))
    }

    const refresh = () => {
        setDummy(!dummy);
    }

    if (loading) {
        return <i>Loading...</i>;
    }

    if (forbidden) {
        return <AccessDenied />;
    }

    if (notFound) {
        return <NotFound />;
    }

    return (
        <Container className="w-75">

            <DelegateReviewerForm
                show={delegateModal}
                onHide={() => setDelegateModal(false)}
                caseID={params.id}
                caseIDs={aiCase.studentsInvolved.map(s => s.caseId)}
                departmentID={aiCase.department.id}
            />
            <a href="/" download={`AI_Incident_${aiCase.caseNum}.zip`} ref={zipAnchorRef} className="d-none">Attachments</a>
            <Row className="mt-2 mb-3 justify-content-between">
                <Col md="auto">
                    <h3>Academic Integrity Incident #{aiCase.caseNum}</h3>
                </Col>
                <Col md="auto">
                    {/* <div className="d-flex"> */}
                    <Row>
                        {showDelegateBtn && aiCase.status.id < 3 &&
                            <Col md="auto">
                                <Button onClick={() => setDelegateModal(true)}>Assign delegate</Button>
                            </Col>
                        }
                        {aiCase.status.id < 3 &&
                            <Col md="auto">
                                <Link to={`/cases/${aiCase.caseNum}/edit`}>
                                    <Button variant="secondary">
                                        <span><FontAwesomeIcon icon={faPencilAlt} /> Edit case</span>
                                    </Button>
                                </Link>
                            </Col>
                        }
                    </Row>

                    {/* </div> */}
                </Col>
            </Row>
            <Table bordered size="sm" className='table'>
                <thead>
                    <tr>
                        <th>Department</th>
                        <th>Course Code</th>
                        <th>Professor Name</th>
                        <th>Professor Email</th>
                        <th>Assessment Name</th>
                        <th>Incident Date</th>
                        <th>Date Reported</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{aiCase.department.name}</td>
                        <td>{aiCase.courseCode}</td>
                        <td>{aiCase.submittedByProfessor}</td>
                        <td>{aiCase.facultyEmail ? aiCase.facultyEmail : "No email"}</td>
                        <td>{aiCase.assessmentName}</td>
                        <td>{new Date(aiCase.dateOfIncident).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} - {aiCase.semester}</td>
                        <td>{new Date(aiCase.dateOfUpload).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</td>
                        <td className="bold underline">{aiCase.status.status}</td>
                    </tr>
                </tbody>
            </Table>

            <h5>Incident description</h5>
            <p>{aiCase.incidentDescription}</p>

            <Row>
                <Col>
                    <h5>Assessment Specifications</h5>
                    {aiCase.assessmentSpecs.length > 0 &&
                        <ul>
                            {aiCase.assessmentSpecs.map((as, i) => (
                                <li key={i}>
                                    <Button className="p-1" variant="link" onClick={() => openInNewTab(as.url)}>{as.name}</Button>
                                </li>
                            ))}
                        </ul>
                    }

                    {aiCase.assessmentSpecs.length === 0 &&
                        <i>No assessment specifications have been uploaded.</i>
                    }
                </Col>
            </Row>

            <Row className="mt-2">
                <h5>Evidence</h5>
                <Col md={11} className={aiCase.evidence.length > 0 ? "ms-4" : ""}>
                    {aiCase.evidence.length > 0 && 
                        <Row className="mb-1">
                            <Col md={4}><strong>File</strong></Col>
                            <Col md={8}><strong>Description</strong></Col>
                        </Row>
                    }
                    {aiCase.evidence.map((ev, i) => (
                        <Row key={i} className="border-bottom pb-2 mb-3">
                            <Col md={4}>
                                <Button variant="link" className="p-0 text-break" onClick={() => openInNewTab(ev.file.url)}>{ev.file.name}</Button>
                            </Col>
                            <Col md={8}>
                                <p className="m-0">{ev.description}</p>
                            </Col>
                        </Row>
                    ))}
                    {aiCase.evidence.length === 0 && <tr><td colSpan={3} className="text-center"><i>Evidence has not been submitted</i></td></tr>}
                </Col>
            </Row>

            <Row className="justify-content-end mb-2">
                <Col md="auto">
                    <Button
                        variant='primary'
                        onClick={downloadZip}
                        disabled={waitingForZip}
                    >
                        {waitingForZip ?
                            <span>Loading <Spinner as="span" animation="border" size="sm" role="status" /></span>
                            : 'Download all attachments'
                        }
                    </Button>
                </Col>
            </Row>

            <h5>Students Involved ({aiCase.studentsInvolved.length})</h5>

            <ListGroup as="ol" className="mb-4" variant="flush" numbered>
                {
                    aiCase.studentsInvolved.map((s, index) => (
                        <ListGroup.Item as="li" key={index} className="d-flex">
                            <Col md={4} className="ms-2">
                                <div className="fw-bold">{s.fullName}</div>
                                <div>Student Number: {s.studentId}</div>
                                <div>Student Email: {s.email}</div>
                                <div className={s.program ? "" : "text-danger fw-bold"}>Program: {s.program ? s.program : "Not assigned"}</div>
                                <div className={s.priorHoldItems ? "" : "text-danger fw-bold"}>Prior Hold Items: {s.priorHoldItems ? s.priorHoldItems : "Not assigned"}</div>
                            </Col>
                            {s.responseMessage ?
                                <Card as={Col} className="align-self-start">
                                    <Card.Header className="bold">
                                        <span>Response on {new Date(s.responseDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span>
                                        {s.responseForms.length > 0 &&
                                            <Button variant="link" onClick={() => openInNewTab(s.responseForms[s.responseForms.length - 1].url)}>Response form</Button>
                                        }
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Text as="div">
                                            {s.responseMessage && <p>{s.responseMessage}</p>}
                                            {s.responseAttachments.length > 0 && <h6>Attachments</h6>}
                                            <ul>
                                                {s.responseAttachments.map((f, i) => (
                                                    <li key={i}>
                                                        <Button variant="link" onClick={() => openInNewTab(f.url)}>{f.name}</Button>
                                                    </li>
                                                ))
                                                }
                                            </ul>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                :
                                <Col className="align-self-start">
                                    <Alert variant="warning" className='d-flex align-items-center mt-4'>
                                        <FontAwesomeIcon icon={faExclamationTriangle} className="ms-3 me-3" />
                                        <span>The student has not provided a response.</span>
                                    </Alert>
                                </Col>
                            }
                        </ListGroup.Item>
                    ))
                }
            </ListGroup>

            {aiCase?.accessDecision && aiCase?.memberCount > 1 &&
                <>
                    {enteringDecisions ?
                        <DecisionEntry caseID={params.id} setEnteringDecisions={setEnteringDecisions} deptID={aiCase.department.id} />
                        : <DecisionView
                            caseID={params.id}
                            poll={aiCase.status.id < 4 && !aiCase.delegated}
                            setEnteringDecisions={setEnteringDecisions}
                            refresh={refresh}
                            origStatus={aiCase.status.id}
                            confirmationsRequired={aiCase.memberCount}
                            delegated={aiCase.delegated}
                        />
                    }
                </>
            }

            {aiCase?.accessDecision && aiCase.memberCount < 2 &&
                <Alert variant='warning' className='d-flex align-items-center mt-4'>
                    <FontAwesomeIcon icon={faExclamationTriangle} className="ms-3 me-3" />
                    <span>Decisions cannot be entered when the committee has less than 2 members.</span>
                </Alert>
            }
        </Container>
    );
}

export default ViewCase;