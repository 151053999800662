import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";

function AccessDenied() {
    return (
        <Container>
            <Row className="flex-column align-items-center justify-content-center" style={{minHeight: "50vh"}}>
                <Col md="auto" className="mb-4">
                    <FontAwesomeIcon icon={faBan} size="5x" color="red"/>
                </Col>
                <Col md="auto">
                    <h2>Forbidden</h2>
                </Col>
                <Col md="auto">
                    <p>You are not allowed to access this resource</p>
                </Col>
            </Row>

        </Container>
    )
}

export default AccessDenied;