import React from 'react';
import { useState, useEffect } from 'react';
import { Badge, Container, Table } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import useDataService from '../../hooks/useDataService';

function StudentCasesList(props) {
    const params = useParams();
    const navigate = useNavigate();

    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { getAllCasesForStudent } = useDataService();

    useEffect(() => {
        setLoading(true);
        getAllCasesForStudent(`test${process.env.REACT_APP_STUDENT_DOMAIN}`)
        .then(data => {
            setStudents(data);
        })
            .catch(err => {
                console.log(err);
                setError(err.message.toString());
            })
            .finally(() => {
                setLoading(false);
            });
    }, [params]);

    return (
        <Container className="w-75">
            <h3 className="mt-2 mb-3">My Incidents</h3>
            {loading && <i>Loading...</i>}
            {error &&
                <div>
                    <p className='error'>Whoops... An error!</p>
                    <p className='error'>{error}</p>
                </div>
            }


            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Case#</th>
                        <th>Response Deadline</th>
                        <th>Professor</th>
                        <th>Course</th>
                    </tr>
                </thead>
                <tbody>
                    {students.map(s => (
                        <tr key={s.caseID} className="clickable" onClick={() => navigate(`/student/cases/${s.caseID}`)}>
                            <td>{s.caseID}</td>
                            <td>
                                {new Date(s.studentresponsedeadline).toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})}
                                { s.statusId === 1 && !s.studentResponseMessage && <Badge pill bg="success" className="ms-2">Open for response</Badge>}
                                { s.statusId > 1 && s.statusId < 3 && !s.studentResponseMessage && <Badge pill bg="danger" className="ms-2">Past due</Badge>}
                                { s.statusId > 2 && <Badge pill bg="secondary" className="ms-2">Closed</Badge>}
                            </td>
                            <td>{s.submittedByProfessor}</td>
                            <td>{s.courseCode}</td>
                        </tr>
                    ))}
                    {students.length === 0 &&
                        <tr>
                            <td colSpan={4} className="text-center"><i>No incidents found</i></td>
                        </tr>
                    }
                </tbody>
            </Table>
        </Container>
    );
}

export default StudentCasesList;
