import CasesList from "../CasesList/CasesList";

function CommitteeCasesList(props) {

    const columns = ['Case #', 'Status', 'Date Created', 'Student', 'Professor', 'Course'];
    const endpoint = '/cases'
    const defaultSort = {
        colIndex: 1,
        asc: true
    };
    
    return <CasesList columns={columns} endpoint={endpoint} header="All Incidents" defaultSort={defaultSort}/>;
}

export default CommitteeCasesList;