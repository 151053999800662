import React, { useState, useEffect, useContext } from 'react';
import './Layout.css'
import CreateCase from './CreateCase/CreateCase.js';
import { Link, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import ViewCase from './ViewCase/ViewCase'
import ProfessorCasesList from './ProfessorCasesList/ProfessorCasesList';
import CommitteeCasesList from './CommitteeCasesList/CommitteeCasesList';
import EditCase from './EditCase/EditCase';
import { UserContext } from '../contexts/UserContext';
import useToken from '../hooks/useToken';
import PrivateRoute from './PrivateRoute';
import Role from '../helpers/roles';
import DepartmentManagement from './Department/DepartmentManagement';
import DepartmentsList from './Department/DepartmentsList';
import StudentCasesList from './StudentCasesList/StudentCasesList';
import AddPriors from './AddPriors/AddPriors';
import StudentResponse from './StudentResponse/StudentResponse';
import NotFound from './Errors/NotFound';
import DecisionLettersList from './DecisionLetters/DecisionLettersList';
import { getHomePage, MENU_ITEMS } from '../helpers/helpers';
import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';


function Layout({ sidebar }) {
    const ProfessorMenuItems = [
        MENU_ITEMS.createCase, MENU_ITEMS.professorsCases
    ];

    const user = useContext(UserContext)[0];
    const [menuItems, setMenuItems] = useState([]);
    const [homePage, setHomePage] = useState();

    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    }

    const [loading, valid] = useToken();

    // once the user context loads, set menu options available to this user
    useEffect(() => {
        setMenuItems([]);
        if (user)
            setHomePage(getHomePage(user.userData.roles));
        if (user && user.userData.roles.find(r => r.role === Role.Student.role)) {
            // for students, show only my incidents
            setMenuItems([MENU_ITEMS.studentsCases]);
        } else if (user && user.userData.roles.find(r => r.role === Role.Professor.role)) {
            let items = ProfessorMenuItems;   // include professor menu items for all @senecacollege.ca users by default
            
            if (user.userData.roles.find(r => [Role.DepartmentAdministrator.role, Role.CommitteeMember.role].includes(r.role))) {
                items = ProfessorMenuItems.concat([MENU_ITEMS.dashboard, MENU_ITEMS.committeesCases, MENU_ITEMS.priors, MENU_ITEMS.decisionLetters]);
            }
            
            if (user.userData.roles.find(r => [Role.SystemAdministrator.role, Role.DepartmentAdministrator.role].includes(r.role))) {
                items.push(MENU_ITEMS.adminDepartments);
            }

            if (user.userData.roles.find(r => r.role === Role.Delegate.role) && !items.find(i => i.path === '/cases')) {
                items.push(MENU_ITEMS.committeesCases);
            }

            setMenuItems(items);
        } else {
            setMenuItems([]);
        }
    }, [user])

    let pathname = usePathname();
    return (


        <div className="wrapper mt-5">
            <nav className={sidebar ? 'sidebar active' : 'sidebar'}>
                <p>Main Menu</p>
                <ul className='list-unstyled'>
                    {menuItems.map((item, index) => {
                        return (
                            <li key={index} className={pathname === item.path/*pathname.includes(item.path)*/ ? 'active' : ''}>
                                <Link to={item.path}>
                                    {item.icon}
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>

            <div className={`mt-2 ${sidebar ? 'content' : 'content active'}`}>
                <Routes>
                    {/* Home/dashboard */}
                    <Route path="/dashboard" element={
                        <PrivateRoute loading={loading} roles={[Role.CommitteeMember, Role.DepartmentAdministrator]}>
                            <Dashboard/>
                        </PrivateRoute>
                    } />

                    {/* Create case feature */}
                    <Route path="/create-case" element={
                        <PrivateRoute loading={loading} roles={[Role.Professor]}>
                            <CreateCase />
                        </PrivateRoute>
                    } />

                    {/* List all cases feature */}
                    <Route path="/cases" element={
                        <PrivateRoute loading={loading} roles={[Role.DepartmentAdministrator, Role.CommitteeMember, Role.Delegate]}>
                            <CommitteeCasesList />
                        </PrivateRoute>
                    } />

                     {/* Decision letters mgmt feature */}
                     <Route path="/cases/letters" element={
                        <PrivateRoute loading={loading} roles={[Role.DepartmentAdministrator, Role.CommitteeMember, Role.Delegate]}>
                            <DecisionLettersList />
                        </PrivateRoute>
                    } />

                    {/* Update case feature */}
                    <Route path="/cases/:id/edit" element={
                        <PrivateRoute loading={loading} roles={[Role.Professor]}>
                            <EditCase />
                        </PrivateRoute>
                    } />

                    {/* View case feature */}
                    <Route path="/cases/:id" element={
                        <PrivateRoute loading={loading} roles={[Role.CommitteeMember, Role.DepartmentAdministrator, Role.Professor]}>
                            <ViewCase />
                        </PrivateRoute>
                    } />

                    <Route path="/add-priors" element={
                        <PrivateRoute loading={loading} roles={[Role.CommitteeMember, Role.DepartmentAdministrator]}>
                            <AddPriors />
                        </PrivateRoute>
                    } />

                    {/* List cases for professor feature */}
                    <Route path="/faculty/cases" element={
                        <PrivateRoute loading={loading} roles={[Role.Professor]}>
                            <ProfessorCasesList />
                        </PrivateRoute>
                    } />

                    {/* List cases for student feature */}
                    <Route path="/student/cases" element={
                        <PrivateRoute loading={loading} roles={[Role.Student]}>
                            <StudentCasesList />
                        </PrivateRoute>
                    } />

                    {/* Student response to case feature */}
                    <Route path="/student/cases/:id" element={
                        <PrivateRoute loading={loading} roles={[Role.Student]}>
                            <StudentResponse />
                        </PrivateRoute>
                    } />

                    {/* List departments feature */}
                    <Route path="/departments" element={
                        <PrivateRoute loading={loading} roles={[Role.DepartmentAdministrator, Role.SystemAdministrator]}>
                            <DepartmentsList />
                        </PrivateRoute>
                    }/>

                    {/* Manage department feature */}
                    <Route path="/departments/:deptId/*" element={
                        <PrivateRoute loading={loading} roles={[Role.SystemAdministrator, Role.DepartmentAdministrator]}>
                            <DepartmentManagement />
                        </PrivateRoute>
                    } />

                    <Route path="/" element={user ? (homePage ? <Navigate to={homePage.path}/> : null) : <Navigate to="/login"/>}/>

                    {/* Not found */}
                    <Route path="*" element={<NotFound/>} />
                </Routes>
            </div>
        </div>
    );
}

export default Layout;
