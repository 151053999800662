import './Decision.css';
import { Formik } from "formik";
import { Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { holdItemsList, incidentTypeList } from "../../../helpers/helpers";
import * as yup from 'yup';
import useDataService from '../../../hooks/useDataService';
import { useEffect, useState } from 'react';

function DecisionEntry({ caseID, setEnteringDecisions, deptID, ...props }) {

    const decisionSchema = yup.object().shape({
        decisions: yup.array().of(yup.object().shape({
            caseID: yup.string().required(),
            studentName: yup.string().required(),
            studentID: yup.string().required(),
            studentEmail: yup.string().required(),
            violationOccured: yup.number().required().oneOf([0, 1], "Invalid selection"),
            sanctions: yup.string().required("Enter 'None' if not applicable").max(128),
            holdItems: yup.string().required().notOneOf(['-- Select hold items --'], "Invalid selection"),
            assignment: yup.number().required().notOneOf([-1], "Invalid selection"),
            incidentType: yup.string().required().notOneOf(['-- Select type --'], "Invalid selection"),
            comments: yup.string().max(1500),
            messageToStudent: yup.string().max(1000)
        }))
    })

    const [decisions, setDecisions] = useState([]);
    const [assignments, setAssignments] = useState([]);

    const { enterDecisions, exitDecisions, getDecisions, getAssignments } = useDataService();

    useEffect(() => {
        getDecisions(caseID)
            .then(decisions => setDecisions(decisions))
            .catch(err => console.log(err))

        getAssignments(deptID)
            .then(assignments => setAssignments(assignments))
            .catch(err => console.log(err))
    }, [])

    const submitDecisions = (formData, setSubmitting) => {
        setSubmitting(true);
        enterDecisions(caseID, formData)
            .then(newDecisions => {
                setSubmitting(false);
                setEnteringDecisions(false);
            })
            .catch(error => console.log(error))
    }


    const handleCancel = () => {
        if (decisions[0]?.violation_occured === null) {
            exitDecisions(caseID)
                .then(newDecisions => {
                    setEnteringDecisions(false)
                })
                .catch(error => {
                    if (error.code === 'ER_LOCKED') {
                        // setCancelError(error.message)
                    }
                    console.log(error);
                })

        } else {
            setEnteringDecisions(false)
        }
    }

    if (decisions.length === 0) {
        return null;
    }

    return (
        <Formik
            initialValues={{
                decisions: decisions.map(d => ({
                    caseID: d.caseID,
                    studentName: d.studentName,
                    studentID: d.studentID,
                    studentEmail: d.studentEmail,
                    violationOccured: d.violation_occured === null ? "-1" : (d.violation_occured ? "1" : "0"),
                    sanctions: d.sanctions ? d.sanctions : "",
                    holdItems: d.holdItems ? d.holdItems : "-- Select hold items --",
                    comments: d.comments ? d.comments : "",
                    assignment: d.assignment ? d.assignment.id : "-1",
                    incidentType: d.incidentType ? d.incidentType : "-- Select type --",
                    messageToStudent: d.messageToStudent ? d.messageToStudent : ""
                }))
            }}
            validationSchema={decisionSchema}
            onSubmit={(values, { setSubmitting }) => submitDecisions(values, setSubmitting)}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                errors
            }) => (
                <Form noValidate onSubmit={handleSubmit} className="case-decision">

                    <ListGroup as="ol" variant="flush" numbered >
                        {values.decisions.map((d, i) => (
                            <ListGroup.Item as="li" key={d.studentID} className="d-flex flex-wrap">
                                <Row className="ms-2 mb-2 w-100">
                                    <Col md={8} className="d-flex flex-column">
                                        <span><strong>{d.studentName}</strong></span>
                                        <span>{d.studentID}</span>
                                        <span>{d.studentEmail}</span>
                                    </Col>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Decision</Form.Label>
                                        <Form.Check
                                            type="radio"
                                            name={`decisions[${i}].violationOccured`}
                                            id={`decisions-${i}-verdict-undecided`}
                                            onChange={handleChange}
                                            label="Undecided"
                                            value="-1"
                                            checked={d.violationOccured === "-1"}
                                        />
                                        <Form.Check
                                            type="radio"
                                            name={`decisions[${i}].violationOccured`}
                                            id={`decisions-${i}-verdict-guilty`}
                                            onChange={handleChange}
                                            label="Guilty"
                                            value="1"
                                            checked={d.violationOccured === "1"}
                                        />
                                        <Form.Check
                                            type="radio"
                                            name={`decisions[${i}].violationOccured`}
                                            id={`decisions-${i}-verdict-not-guilty`}
                                            onChange={handleChange}
                                            label="Not Guilty"
                                            value="0"
                                            checked={d.violationOccured === "0"}
                                        />
                                        {errors?.decisions?.[i]?.violationOccured &&
                                            <div className="invalid-feedback d-block">{errors?.decisions?.[i]?.violationOccured}</div>
                                        }
                                    </Form.Group>
                                </Row>
                                <Row className="w-100 ms-2 mb-2">
                                    <Form.Group as={Col} md={6} controlId={`decisions.[${i}].sanctions`}>
                                        <Form.Label>Sanctions</Form.Label>
                                        <Form.Control
                                            name={`decisions[${i}].sanctions`}
                                            onChange={handleChange}
                                            value={d.sanctions}
                                            placeholder="Enter sanctions or 'None'"
                                            isInvalid={!!errors.decisions?.[i]?.sanctions}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.decisions?.[i]?.sanctions}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId={`decisions[${i}].holdItems`}>
                                        <Form.Label>Hold Items</Form.Label>
                                        <Form.Select
                                            name={`decisions[${i}].holdItems`}
                                            onChange={handleChange}
                                            value={d.holdItems}
                                            isInvalid={!!errors.decisions?.[i]?.holdItems}
                                        >
                                            <option value="-- Select hold items --">-- Select hold items --</option>
                                            {holdItemsList.map((e, i) => (
                                                <option key={i} value={e}>{e}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.decisions?.[i]?.holdItems}</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                                <Row className="w-100 ms-2 mb-2">
                                    <Col md={6}>
                                        <Form.Group className="mb-2" controlId={`decisions[${i}].assignment`}>
                                            <Form.Label>Assignment for student</Form.Label>
                                            <Form.Select
                                                name={`decisions[${i}].assignment`}
                                                onChange={handleChange}
                                                value={d.assignment}
                                                isInvalid={!!errors.decisions?.[i]?.assignment}
                                            >
                                                <option value="-1">-- Select assignment --</option>
                                                <option value="0">None</option>
                                                {assignments.map((e, i) => (
                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{errors.decisions?.[i]?.assignment}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId={`decisions[${i}].incidentType`}>
                                            <Form.Label>Incident Type</Form.Label>
                                            <Form.Select
                                                name={`decisions[${i}].incidentType`}
                                                onChange={handleChange}
                                                value={d.incidentType}
                                                isInvalid={!!errors.decisions?.[i]?.incidentType}
                                            >
                                                <option value="-- Select type --">-- Select type --</option>
                                                {incidentTypeList.map((e, i) => (
                                                    <option key={i} value={e}>{e}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{errors.decisions?.[i]?.incidentType}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Form.Group as={Col} md={6} controlId={`decisions[${i}].comments`}>
                                        <Form.Label>Decision notes (Internal)</Form.Label>
                                        <Form.Control as="textarea"
                                            rows={4}
                                            placeholder="Optional notes for internal use"
                                            aria-label="comments"
                                            name={`decisions[${i}].comments`}
                                            onChange={handleChange}
                                            value={d.comments}
                                        // isInvalid={!!errors.evidence?.[index]?.description}
                                        />
                                        {/* <Form.Control.Feedback type="invalid">{errors.evidence?.[index]?.description}</Form.Control.Feedback> */}
                                    </Form.Group>
                                </Row>
                                <Row className="w-100 ms-2">
                                    <Form.Group as={Col} md={12} controlId={`decisions[${i}].messageToStudent`}>
                                        <Form.Label>Message to Student</Form.Label>
                                        <Form.Control as="textarea"
                                            rows={3}
                                            placeholder="Response message to the student"
                                            aria-label="messageToStudent"
                                            name={`decisions[${i}].messageToStudent`}
                                            onChange={handleChange}
                                            value={d.messageToStudent}
                                        // isInvalid={!!errors.evidence?.[index]?.description}
                                        />
                                        {/* <Form.Control.Feedback type="invalid">{errors.evidence?.[index]?.description}</Form.Control.Feedback> */}
                                    </Form.Group>
                                </Row>
                                {/* </div> */}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <Row className="justify-content-between mt-3">
                        <Col md="auto">
                            <Button
                                variant="outline-danger"
                                onClick={handleCancel}
                            >Cancel
                            </Button>
                        </Col>
                        <Col md="auto">
                            <Button variant="primary" type="submit">Submit for confirmation</Button>
                        </Col>
                    </Row>
                    <div style={{ minHeight: "25vh" }} />
                </Form>
            )}
        </Formik>
    )
}

export default DecisionEntry;