import { FieldArray, Formik } from "formik";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import useDataService from "../../hooks/useDataService";
import * as yup from 'yup';
import InfoHelp from "./InfoHelp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

function EditDepartment({ department, setDepartment, setEditing, helpText, ...props }) {

    class Assignment {
        name = "";
        description = "";
        consequence = "";
        hidden = false;
    }

    const { updateDepartment } = useDataService();

    const [error, setError] = useState();

    const departmentSchema = yup.object().shape({
        name: yup.string().required('Name is required').max(10),
        fullName: yup.string().required('Full name is required').max(100),
        guiltyPreamble: yup.string().required('Guilty preamble is required').max(1500),
        guiltyPostamble: yup.string().required('Guilty postamble is required').max(2000),
        innocentPreamble: yup.string().required('Innocent preamble is required').max(1500),
        innocentPostamble: yup.string().required('Innocent postamble is required').max(2000),
        assignments: yup.array().of(yup.object({
            name: yup.string().required('Short assignment name is required').max(25),
            description: yup.string().required('Description is required').max(500),
            consequence: yup.string().max(500),
            hidden: yup.boolean().required()
        }))
    });

    const editDepartment = (formData, setSubmitting) => {
        setError();
        setSubmitting(true);
        updateDepartment(department.id, formData)
            .then(dept => {
                setSubmitting(false);
                setDepartment(dept);
                setEditing(false);
            })
            .catch(error => {
                console.log(error);
                setSubmitting(false);
                setError(error.message.toString());
            });
    }

    return (
        <Formik
            initialValues={{
                name: department.name,
                fullName: department.fullName,
                guiltyPreamble: department.guiltyPreamble,
                guiltyPostamble: department.guiltyPostamble,
                innocentPreamble: department.innocentPreamble,
                innocentPostamble: department.innocentPostamble,
                assignments: department.assignments
            }}
            validationSchema={departmentSchema}
            onSubmit={(values, { setSubmitting }) => editDepartment(values, setSubmitting)}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                errors,
                isSubmitting
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col md={3}>
                            <Form.Group as={Col} controlId="name">
                                <Form.Label>Department Abbreviation</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="DEPT"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Col} controlId="fullName">
                                <Form.Label>Department Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fullName"
                                    placeholder="Department of Example"
                                    value={values.fullName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.fullName}
                                />
                                <Form.Control.Feedback type="invalid">{errors.fullName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h5 className="mt-3">
                        <span className="me-4">"Guilty" letter template</span>
                        <InfoHelp message={helpText.guilty} />
                    </h5>
                    <Form.Group controlId="guiltyPreamble" className="mt-3">
                        <Form.Label>Guilty preamble</Form.Label>
                        <Form.Control
                            type="text" as="textarea"
                            rows={5}
                            name="guiltyPreamble"
                            placeholder="Guilty preamble"
                            value={values.guiltyPreamble}
                            onChange={handleChange}
                            isInvalid={!!errors.guiltyPreamble}
                        />
                        <Form.Control.Feedback type="invalid">{errors.guiltyPreamble}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="guiltyPostamble" className="mt-3">
                        <Form.Label>Guilty postamble</Form.Label>
                        <Form.Control
                            type="text" as="textarea"
                            rows={5}
                            name="guiltyPostamble"
                            placeholder="Guilty postamble"
                            value={values.guiltyPostamble}
                            onChange={handleChange}
                            isInvalid={!!errors.guiltyPostamble}
                        />
                        <Form.Control.Feedback type="invalid">{errors.guiltyPostamble}</Form.Control.Feedback>
                    </Form.Group>
                    <h5 className="mt-3">
                        <span className="me-4">"Innocent" letter template</span>
                        <InfoHelp message={helpText.innocent} />
                    </h5>
                    <Form.Group controlId="innocentPreamble" className="mt-3">
                        <Form.Label>Innocent preamble</Form.Label>
                        <Form.Control
                            type="text" as="textarea"
                            rows={5}
                            name="innocentPreamble"
                            placeholder="Innocent Preamble"
                            value={values.innocentPreamble}
                            onChange={handleChange}
                            isInvalid={!!errors.innocentPreamble}
                        />
                        <Form.Control.Feedback type="invalid">{errors.innocentPreamble}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="innocentPostamble" className="mt-3">
                        <Form.Label>Innocent postamble</Form.Label>
                        <Form.Control
                            type="text" as="textarea"
                            rows={5}
                            name="innocentPostamble"
                            placeholder="Innocent postamble"
                            value={values.innocentPostamble}
                            onChange={handleChange}
                            isInvalid={!!errors.innocentPostamble}
                        />
                        <Form.Control.Feedback type="invalid">{errors.innocentPostamble}</Form.Control.Feedback>
                    </Form.Group>
                    <h5 className="mt-3 mb-2">Assignments</h5>
                    <FieldArray
                        name="assignments"
                        validateOnChange={false}
                        render={arrayHelpers => (
                            <div>
                                {values.assignments.map((a, i) => (
                                    <Row key={i} className="mb-2 pb-3 border-bottom">
                                        <Col md={2}>
                                            <Form.Group controlId={`assignments[${i}].name`}>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name={`assignments[${i}].name`}
                                                    onChange={handleChange}
                                                    value={a.name}
                                                    isInvalid={!!errors.assignments?.[i]?.name}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.assignments?.[i]?.name}</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId={`assignments[${i}].hidden`} className="mt-3">
                                                <Form.Label>Hide assignment</Form.Label>
                                                <Form.Check
                                                    name={`assignments[${i}].hidden`}
                                                    type="checkbox"
                                                    checked={a.hidden === true || a.hidden === 'on'}
                                                    onChange={handleChange}
                                                    label="Hide"
                                                    isInvalid={!!errors.assignments?.[i]?.hidden}
                                                />
                                                <Form.Text>Do not show during decisions</Form.Text>
                                                <Form.Control.Feedback type="invalid">{errors.assignments?.[i]?.hidden}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Group className="mb-2" controlId={`assignments[${i}].description`}>
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    type="text" as="textarea"
                                                    rows={2}
                                                    name={`assignments[${i}].description`}
                                                    placeholder="Assignment description"
                                                    value={a.description}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.assignments?.[i]?.description}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.assignments?.[i]?.description}</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId={`assignments[${i}].consequence`}>
                                                <Form.Label>Consequence</Form.Label>
                                                <Form.Control
                                                    type="text" as="textarea"
                                                    rows={2}
                                                    name={`assignments[${i}].consequence`}
                                                    placeholder="Consequence if the assignment is not completed"
                                                    value={a.consequence}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.assignments?.[i]?.consequence}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.assignments?.[i]?.consequence}</Form.Control.Feedback>
                                            </Form.Group>

                                        </Col>
                                        <Col md={1} className="align-self-center">
                                            {!a.id &&
                                                <div style={{ textAlign: "center", fontSize: "1.5em" }}>
                                                    <FontAwesomeIcon
                                                        className="trash-delete"
                                                        icon={faTrashAlt}
                                                        onClick={() => arrayHelpers.remove(i)}
                                                    />
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                ))}
                                <Button
                                    variant="success"
                                    onClick={() => arrayHelpers.push(new Assignment())}
                                    className="mb-3 mt-2"
                                >
                                    <strong>+ </strong>Add assignment
                                </Button>
                            </div>
                        )}
                    />
                    <Row className="mt-4 justify-content-end">
                        <Col md="auto">
                            <Button disabled={isSubmitting} type="submit">Update department information</Button>
                        </Col>
                    </Row>
                    {error &&
                        <div className='invalid-feedback d-block'>
                            {error}
                        </div>
                    }
                </Form>
            )}

        </Formik>
    )
}

export default EditDepartment;