import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function InfoHelp({ message, ...props }) {
    return (
        <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="test">{message}</Tooltip>}
        >
            <a className="info-hover"><FontAwesomeIcon icon={faInfoCircle} /></a>
        </OverlayTrigger>
    )
}

export default InfoHelp;