import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import useDataService from "../../hooks/useDataService";
import { UserContext } from "../../contexts/UserContext";
import Role from '../../helpers/roles';
import NewDepartmentForm from "./NewDepartmentForm";

function DepartmentsList(props) {

    const { getDepartments } = useDataService();
    const user = useContext(UserContext)[0];

    const [loading, setLoading] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [error, setError] = useState('');
    const [postError, setPostError] = useState("");
    const [adding, setAdding] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        getDepartments() //params.id
            .then(data => {
                setDepartments(data);
            })
            .catch(err => {
                setError(err.message.toString());
            })
            .finally(() => {
                setLoading(false);
            });
        setDepartments([]);
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <Container className="mt-4 w-75">
            <NewDepartmentForm show={adding} handleClose={() => setAdding(false)} setDepartments={setDepartments}/>
            <Row className="mb-3 justify-content-between">
                <Col>
                    <h3>Departments</h3>
                </Col>
                {user.userData.roles.find(r => r.role === Role.SystemAdministrator.role) &&
                    <Col md="auto">
                        <Button variant='outline-primary' onClick={() => setAdding(true)}>
                            Add department
                        </Button>
                    </Col>
                }
            </Row>

            <Table hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Full Name</th>
                    </tr>
                </thead>
                <tbody>
                    {departments.map(p => (
                        <tr key={p.id} className="clickable" onClick={() => navigate(`/departments/${p.id}`)}>
                            <td>{p.name}</td>
                            <td>{p.fullName}</td>
                        </tr>
                    ))}
                    {departments.length === 0 &&
                        <tr>
                            <td className="text-center" colSpan={2}><i>No Departments yet!</i></td>
                        </tr>
                    }
                </tbody>
            </Table>

        </Container>
    )
}
export default DepartmentsList;