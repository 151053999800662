import { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { getHomePage } from "../../helpers/helpers";

/**
 * This component captures the JWT token from the query string
 * and saves it to the session storage. The token will be validated
 * after navigating to "/" past the lobby
 * @returns 
 */

function LoginRedirect() {
    const token = useSearchParams()[0].get('jwt');

    const [homePage, setHomePage] = useState();

    useEffect(() => {
        sessionStorage.setItem('jwt_token', token);
        fetch(`${process.env.REACT_APP_API_URL}/whoami`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setHomePage(getHomePage(data.roles))
            })
            .catch(err => console.log(err));
    }, []);

    if (homePage)
        return <Navigate to={homePage.path} />;
    
    return null;
}

export default LoginRedirect;