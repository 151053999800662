import ConfirmationPage from "./ConfirmationPage";
import FacultyInfoStep from "./FacultyInfoStep";
import IncidentSummaryStep from "./IncidentSummaryStep";
import StudentInfoStep from "./StudentInfoStep";


function CreateCaseForm(props) {
    switch (props.step) {
        case 1:
            return <FacultyInfoStep
                addFacultyInfo={props.addFacultyInfo}
                formData={props.formData}
            />;
        case 2:
            return <StudentInfoStep
                addStudentInfo={props.addStudentInfo}
                previousStep={props.previousStep}
                formData={props.formData}
            />;
        case 3:
            // student-incident bridge
            return <IncidentSummaryStep
                previousStep={props.previousStep}
                formData={props.formData}
                submit={props.submit}
                confirmation={props.confirmation}  
            />;
        case 4:
            // confirmation page
            return <ConfirmationPage
                responseData={props.responseData}
            />;
        default:
            return null;
    }
}

export default CreateCaseForm;
