import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function CasesListSection(props) {
    const showStatuses = props.showStatuses;

    const navigate = useNavigate();
    function handleRowClick(id) {
        navigate(`/cases/${id}`);
    }

    const pastDue = (new Date() > new Date(props.data.deadline)     // date now is after deadline
        && props.data.cases[0].status.id < 3                        // case is awaiting decision
        && (props.data.evidenceCount === 0 || props.data.specsCount === 0)  // attachements haven't been submitted yet
    );

    return (
        <tbody>
            <tr className="case-clickable bg-secondary bg-opacity-10" onClick={handleRowClick.bind(this, props.data.groupID)}>
                <th colSpan={props.columns.length}>
                    {props.data.groupID} - {props.data.department.name}
                    {showStatuses &&
                        <>
                            { pastDue && <Badge pill bg="danger" className="ms-2">Past due</Badge>}
                            { props.data.cases[0].status.id > 2 && <Badge pill bg="secondary" className="ms-2">Closed</Badge>}
                            { props.data.cases[0].status.id < 2 && <Badge pill bg="success" className="ms-2">Open for attachments</Badge>}
                        </>
                    }
                </th>
            </tr>
            {props.data.cases.map((c, index) => (
                <tr key={c.caseID}>
                    {props.columns.map((col, i) => {
                        switch(col) {
                            case 'Case #':
                                return <td className="text-center" key={`case-id-${index}`}>{c.caseID}</td>;
                            case 'Status':
                                return <td key={`case-status-${index}`}>{c.status.status}</td>;
                            case 'Date Created':
                                return <td key={`case-created-date-${index}`}>{new Date(c.submitted_date).toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})}</td>;
                            case 'Student':
                                return <td key={`case-student-${index}`}>{c.studentName}</td>;
                            case 'Professor':
                                return <td key={`case-professor-${index}`}>{c.submittedByProfessor}</td>;
                            case 'Course':
                                return <td key={`case-course-${index}`}>{c.courseCode}</td>;
                            case 'Evidence':
                                return <td key={`case-evidence-${index}`} style={{textAlign: "center"}}>
                                    {c.evidenceCount > 0 ?
                                        <FontAwesomeIcon icon={faCheck} size="lg"/>
                                        : null
                                    }
                                </td>;
                            case 'Assessment Specifications':
                                return <td key={`case-specs-${index}`} style={{textAlign: "center"}}>
                                    {c.specsCount > 0 ? 
                                        <FontAwesomeIcon icon={faCheck} size="lg"/>
                                        : null
                                    }
                                </td>;
                            case 'Select Case':
                                return <td key={`case-selection-${index}`}>
                                    {c.status.id === 4 && // in case a letter has been sent for only one of linked (group) cases
                                    <Form.Check
                                        type="checkbox"
                                        name="caseIDs"
                                        id={`${c.caseID}-selection`}
                                        onChange={event => {
                                            props.selectionForm.setFieldValue("")
                                            props.selectionForm.handleChange(event);
                                        }}
                                        label={c.caseID}
                                        value={c.caseID}
                                        checked={props.selectionForm.values.caseIDs.includes(c.caseID)}
                                    />
                                    }
                                </td>
                            case 'Deadline': 
                                return <td key={`case-deadline-${index}`}>{new Date(c.deadline).toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})}</td>;
                            case 'Letter type':
                                return <td key={`case-letter-type-${index}`}>{c.customDecision ? 'Custom' : 'System'}</td>;
                                default:
                                return null;
                        }
                    })}
                </tr>
            ))
            }
        </tbody>
    )
}

export default CasesListSection;