import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { stringifyCaseDetails } from "../helpers/helpers";

function useDataService() {
    const user = useContext(UserContext)[0];

    const fetchWrapper = (endpoint, options) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                method: options?.method ? options.method : 'GET',
                headers: {
                    'Content-Type': options?.body ? 'application/json' : null,
                    'Authorization': `Bearer ${user.token}`
                },
                body: options?.body
            })
                .then(response => {
                    if (response.status !== 401) {      // pass status and JSON body down the chain if not 401 Unauthorized
                        return response.json().then(data => ({ status: response.status, data: data }))
                    } else {
                        return {                        // pass 401 and pre-defined JSON body if 401 Unauthorized
                            status: 401,
                            data: {
                                status: 401,
                                code: 'UNAUTHORIZED',
                                message: 'Authentication required'
                            }
                        }
                    }
                })
                .then(response => {                     // at this point response has both status and JSON data
                    if ([200, 201].includes(response.status)) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                })
                .catch(error => reject(error));        // unsuccessful statuses (e.g. 400, 401) do NOT go into this catch block
        })
    }

    const blobFetchWrapper = (endpoint, options) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                method: options?.method ? options.method : 'GET',
                headers: {
                    'Content-Type': options?.body ? 'application/json' : null,
                    'Authorization': `Bearer ${user.token}`
                },
                body: options?.body
            })
                .then(response => {
                    if (response.status !== 401) {      // pass status and JSON body down the chain if not 401 Unauthorized
                        return response.blob().then(data => ({ status: response.status, data: data }))
                    } else {
                        return {                        // pass 401 and pre-defined JSON body if 401 Unauthorized
                            status: 401,
                            data: {
                                status: 401,
                                code: 'UNAUTHORIZED',
                                message: 'Authentication required'
                            }
                        }
                    }
                })
                .then(response => {                     // at this point response has both status and JSON data
                    if ([200, 201].includes(response.status)) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                })
                .catch(error => reject(error));        // unsuccessful statuses (e.g. 400, 401) do NOT go into this catch block
        })
    }

    /**
     * Get AI Case by ID by querying '/cases/:id' endpont.
     * @param {String} id 
     * @returns Promise that resolves with the case object
     */
    const getCaseById = id => {
        return fetchWrapper(`/cases/${id}`)
    }

    /**
     * Get all cases from a provided endpoint:
     * - Committee: '/cases'
     * - Professors: '/cases/faculty'
     * @param {String} endpoint 
     * @param {String} semester 
     * @returns Promise that resolves with an array of case objects
     */
    const getCases = (endpoint, semester) => {
        return fetchWrapper(`${endpoint}?` + new URLSearchParams({ semester: semester }))
    }

    /**
     * POSTs the form data to /cases
     * @param {Object} data 
     * @returns Promise that resolves with the summaries of cases created
     */
    const createCase = data => {
        return fetchWrapper(`/cases`, {
            method: 'POST',
            body: JSON.stringify(data, (name, value) => {
                if (name === 'caseDetails') {
                    return stringifyCaseDetails(value);
                } else if (name === 'facultyEmail') {
                    return `${value}${process.env.REACT_APP_FACULTY_DOMAIN}`;
                } else if (name === 'studentEmail') {
                    return `${value}${process.env.REACT_APP_STUDENT_DOMAIN}`;
                } else {
                    return value;
                }
            })
        })
    }

    const getDepartmentOptions = () => {
        return fetchWrapper(`/cases/department-options`);
    }

    /**
     * Update AI Case. Will send a PUT request to '/cases/:id' endpoint.
     * 
     * @param {*} caseObj 
     * @returns Promise that resolves with file upload URLs
     */
    const updateCase = caseObj => {
        return fetchWrapper(`/cases/${caseObj.caseID}`, {
            method: 'PUT',
            body: JSON.stringify(caseObj, (name, value) => {
                if (name === 'specsFiles') {
                    return value.map(e => ({
                        id: e.id,
                        name: e.name
                    }));
                } else if (name === 'evidence') {
                    return value.map(e => ({
                        id: e.id,
                        name: e.file.name,
                        description: e.description,
                        student: e.student
                    }));
                } else {
                    return value;
                }
            })
        })
    }

    const getAllCasesForStudent = () => {
        return fetchWrapper(`/cases/student`);
    }

    const getStudentCaseById = (caseID) => {
        return fetchWrapper(`/cases/student/${caseID}`);
    }

    /**
     * Update student's response to a case. Will send a PUT request to '/cases/:id/response' endpoint.
     * 
     * @param {Object} caseObj 
     * @returns Promise that resolves with file upload URLs
     */
    const updateStudentResponse = caseObj => {
        return fetchWrapper(`/cases/${caseObj.caseID}/response`, {
            method: 'PUT',
            body: JSON.stringify(caseObj, (name, value) => {
                if (name === 'attachments') {
                    return value.map(e => ({
                        id: e.id,
                        name: e.name
                    }));
                } else {
                    return value;
                }
            })
        })
    }

    const updatePriorsAndProgram = (caseId, data) => {
        return fetchWrapper( `/cases/${caseId}/priors`, {
                method: 'PUT',
                body: JSON.stringify(data)
            });
    }

    /**
     * Gets all semesters from the table of cases
     * @returns Promise that resolves with a list of all semesters for which cases exist
     */
    const getAllSemesters = () => {
        return fetchWrapper(`/cases/semesters`);
    }

    /**
     * Gets departments
     * @returns Promise that resolves with a list of departments
     */
    const getDepartments = () => {
        return fetchWrapper(`/departments`);
    }

    /**
     * Creates a new department
     * @param {Object} formData 
     * @returns 
     */
    const addDepartment = (formData) => {
        return fetchWrapper(`/departments`, {
            method: 'POST',
            body: JSON.stringify(formData, (name, value) => {
                if (name === 'adminEmail') {
                    return `${value}${process.env.REACT_APP_FACULTY_DOMAIN}`
                } else {
                    return value;
                }
            })
        });
    }

    /**
     * 
     * @param {Number} department 
     * @returns 
     */
    const getDepartmentById = (department) => {
        return fetchWrapper(`/departments/${department}`);
    }

    /**
     * 
     * @param {Number} department 
     * @param {Object} formData 
     * @returns 
     */
    const updateDepartment = (department, formData) => {
        return fetchWrapper(`/departments/${department}`, {
            method: 'PUT',
            body: JSON.stringify(formData)
        });
    }

    /**
     * Gets all user roles assigned within department
     * @param {Number} department department ID
     * @returns Promise that resolves with list of department roles
     */
    const getDepartmentRoles = (department) => {
        return fetchWrapper(`/departments/${department}/roles`);
    }


    /**
     * Inserts a role assignment for user in the department
     * @param {Number} department department ID
     * @param {Object} formData 
     * @returns Promise that resolves with the list of updated department roles
     */
    const assignDepartmentRole = (department, formData) => {
        return fetchWrapper(`/departments/${department}/roles`, {
            method: 'POST',
            body: JSON.stringify(formData, (name, value) => {
                if (name === 'email') {
                    return `${value}${process.env.REACT_APP_FACULTY_DOMAIN}`
                } else {
                    return value;
                }
            })
        });
    }

    /**
     * Removes the role assignment in the department
     * @param {Number} department department ID
     * @param {Number} role role assignment ID
     * @returns Promise that resolves with the list of updated department roles
     */
    const removeDepartmentRole = (department, role) => {
        return fetchWrapper(`/departments/${department}/roles/${role}`, {
            method: 'DELETE'
        })
    }

    /**
     * Gets all contacts associated with the department
     * @param {Number} department department ID
     * @returns Promise that resolve with the list of department contacts
     */
    const getDepartmentContacts = (department) => {
        return fetchWrapper(`/departments/${department}/contacts`);
    }

    /**
     * Adds a new contact to the department
     * @param {Number} department department ID
     * @param {*} formData 
     * @returns Promise that resolves with the updated list of department contacts
     */
    const assignDepartmentContact = (department, formData) => {
        let data = { ...formData };
        if (data.role === 'Other') {
            data.role = data.altRole;
            delete data.altRole;
        }
        return fetchWrapper(`/departments/${department}/contacts`, {
            method: 'POST',
            body: JSON.stringify(data, (name, value) => {
                if (name === 'email') {
                    return `${value}${process.env.REACT_APP_FACULTY_DOMAIN}`
                } else {
                    return value;
                }
            })
        })
    }

    /**
     * Removes the contact from the department
     * @param {Number} department department ID
     * @param {Number} role role assignment ID
     * @returns Promise that resolves with the updated list of department contacts
     */
    const removeDepartmentContact = (department, role) => {
        return fetchWrapper(`/departments/${department}/contacts/${role}`, {
            method: 'DELETE'
        });
    }

    /**
     * Gets all programs associated with the department
     * @param {Number} department department ID
     * @returns Promise that resolves with the list of all programs related to the department
     */
    const getPrograms = (department) => {
        return fetchWrapper(`/departments/${department}/programs`);
    }

    /**
     * Gets all a certain program associated with the department
     * @param {Number} department department ID
     * @param {Number} program program ID
     * @returns Promise that resolves with the program related to the department
     */
    const getProgramById = (department, program) => {
        return fetchWrapper(`/departments/${department}/programs/${program}`);
    }

    const addProgram = (department, formData) => {
        return fetchWrapper(`/departments/${department}/programs`, {
            method: 'POST',
            body: JSON.stringify(formData)
        });
    }

    const updateProgram = (department, program, formData) => {
        return fetchWrapper(`/departments/${department}/programs/${program}`, {
            method: 'PUT',
            body: JSON.stringify(formData)
        });
    }

    /**
     * Gets all contacts associated with the program
     * @param {Number} department department ID
     * @param {Number} program program ID
     * @returns Promise that resolves with the list of all contacts in the program
     */
    const getProgramContacts = (department, program) => {
        return fetchWrapper(`/departments/${department}/programs/${program}/contacts`);
    }

    /**
     * Adds a new contact to the department's program
     * @param {Number} department department ID
     * @param {Object} formData 
     * @param {Number} program program ID
     * @returns Promise that resolves with the updated list of program contacts
     */
    const assignProgramContact = (department, formData, program) => {
        let data = { ...formData };
        if (data.role === 'Other') {
            data.role = data.altRole;
            delete data.altRole;
        }
        return fetchWrapper(`/departments/${department}/programs/${program}/contacts`, {
            method: 'POST',
            body: JSON.stringify(data, (name, value) => {
                if (name === 'email') {
                    return `${value}${process.env.REACT_APP_FACULTY_DOMAIN}`
                } else {
                    return value;
                }
            })
        });
    }

    /**
     * Removes the contact from the program
     * @param {Number} department department ID
     * @param {Number} role role assignment ID
     * @param {Number} program program ID
     * @returns Promise that resolves with the updated list of program contacts
     */
    const removeProgramContact = (department, role, program) => {
        return fetchWrapper(`/departments/${department}/programs/${program}/contacts/${role}`, {
            method: 'DELETE'
        });
    }

    const lockDecisions = (caseID) => {
        return fetchWrapper(`/cases/${caseID}/decisions/lock`, {
            method: 'POST'
        });
    }

    const exitDecisions = (caseID) => {
        return fetchWrapper(`/cases/${caseID}/decisions/lock`, {
            method: 'DELETE'
        });
    }

    const enterDecisions = (caseID, decisions) => {
        return fetchWrapper(`/cases/${caseID}/decisions`, {
            method: 'PUT',
            body: JSON.stringify(decisions)
        })
    }

    const getDecisions = caseID => {
        return fetchWrapper(`/cases/${caseID}/decisions`);
    }

    const confirmDecision = caseID => {
        return fetchWrapper(`/cases/${caseID}/decisions/confirm`, {
            method: 'POST'
        })
    }

    const closeCase = caseID => {
        return fetchWrapper(`/cases/${caseID}/decisions/close`, {
            method: 'POST'
        })
    }

    const addDelegate  = (caseID, data) => {
        return fetchWrapper(`/cases/${caseID}/delegate`, {
            method: 'POST', 
            body: JSON.stringify(data, (name, value) => {
                if (name === 'delegateEmail') {
                    return `${value}${process.env.REACT_APP_FACULTY_DOMAIN}`
                } else {
                    return value;
                }
            })
        })
    }

    const getDelegates = (caseID, caseIDs) => {
        return fetchWrapper(`/cases/${caseID}/delegates?ids=${caseIDs.join(",")}`);
    }

    const deleteDelegate = (caseID, caseIDs, email) => {
        return fetchWrapper(`/cases/${caseID}/delegates?ids=${caseIDs.join(",")}&email=${email}`, {
            method: 'DELETE'
        });
    }

    const getAssignments = (deptId) => {
        return fetchWrapper(`/departments/${deptId}/assignments`);
    }

    const getStatuses = () => {
        return fetchWrapper(`/cases/statuses`);
    }

    const getDecisionLetters = (data) => {
        return fetchWrapper('/cases/letters', {
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    const getCSV = (data) => {
        return blobFetchWrapper('/cases/csv', {
            method: 'POST',
            body: JSON.stringify(data)
        })
    }

    const updateDecisionLetters = (data) => {
        return fetchWrapper('/cases/custom-letters', {
            method: 'POST',
            body: JSON.stringify(data, (name, value) => {
                if (name === 'file') 
                    return undefined
                else 
                    return value
            })
        })
    }

    const sendLetters = (data) => {
        return fetchWrapper('/cases/send-letters', {
            method: 'POST',
            body: JSON.stringify(data)
        })
    }

    const getCasesSummary = () => {
        return fetchWrapper('/cases/summary');
    }

    return {
        getCaseById,
        getCases,
        createCase,
        getDepartmentOptions,
        updateCase,
        getAllCasesForStudent,
        getStudentCaseById,
        updateStudentResponse,
        getAllSemesters,
        getDepartments,
        addDepartment,
        getDepartmentById,
        updateDepartment,
        getDepartmentRoles,
        assignDepartmentRole,
        removeDepartmentRole,
        getDepartmentContacts,
        assignDepartmentContact,
        removeDepartmentContact,
        getPrograms,
        getProgramById,
        addProgram,
        updateProgram,
        getProgramContacts,
        assignProgramContact,
        removeProgramContact,
        updatePriorsAndProgram,
        lockDecisions,
        exitDecisions,
        enterDecisions,
        getDecisions,
        confirmDecision,
        closeCase,
        addDelegate,
        getDelegates,
        deleteDelegate,
        getAssignments,
        getStatuses,
        getDecisionLetters,
        getCSV,
        updateDecisionLetters,
        sendLetters,
        getCasesSummary
    };
}

export default useDataService;