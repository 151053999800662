import { faSearch, faSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";

function NotFound() {
    return (
        <Container>
            <Row className="flex-column align-items-center justify-content-center" style={{minHeight: "50vh"}}>
                <Col md="auto" className="mb-4">
                    <FontAwesomeIcon icon={faTimes} size="5x" color="grey"/>
                </Col>
                <Col md="auto">
                    <h2>Not Found</h2>
                </Col>
                <Col md="auto">
                    <p>The resource you requested does not exist</p>
                </Col>
            </Row>

        </Container>
    )
}

export default NotFound;