import { useContext } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

/**
 * This component resets the session storage and context.
 * Rendered when the back-end logout callback redirects.
 * @returns
 */
function LogoutRedirect() {
    const setUser = useContext(UserContext)[1];
    useEffect(() => {
        sessionStorage.clear();
        setUser(null);
    }, [])

    return <Navigate to="/"/>;

}

export default LogoutRedirect;