import { Container } from "react-bootstrap";
import CasesList from "../CasesList/CasesList";


function ProfessorCasesList(props) {

    const columns = ['Case #', 'Student', 'Course', 'Evidence', 'Assessment Specifications', 'Status', 'Deadline'];
    const endpoint = '/cases/faculty';
    const defaultSort = {
        colIndex: 0,
        asc: false
    }

    return (
        <Container>
            <CasesList columns={columns} endpoint={endpoint} header="Incidents Reported by Me" defaultSort={defaultSort} showStatuses={true}/>
        </Container>
    )
}

export default ProfessorCasesList;