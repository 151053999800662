import { Card } from "react-bootstrap";
import { statusDescriptions } from "../../helpers/helpers";

function StatPanel({data, ...props}) {
    return (
        <Card className="h-100">
            <Card.Header style={{minHeight: "4em"}}>{data.status}</Card.Header>
            <Card.Body>
                <Card.Title>{data.count} <small>incidents</small></Card.Title>
                <Card.Text className="text-muted">{statusDescriptions[data.id - 1]}</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default StatPanel;