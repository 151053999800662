import { Formik } from 'formik';
import { useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, FormControl, InputGroup, Row, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { UserContext } from '../../contexts/UserContext';
import Role from '../../helpers/roles';
import useDataService from '../../hooks/useDataService';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

function DelegateReviewerForm({ show, onHide, caseID, caseIDs, departmentID, ...props }) {
    const user = useContext(UserContext)[0];

    const [members, setMembers] = useState([]);
    const [delegates, setDelegates] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState();

    const emailRegex = new RegExp("^[a-zA-Z0-9._-]+" + process.env.REACT_APP_FACULTY_DOMAIN + "$");

    const delegateSchema = yup.object().shape({
        option: yup.number().required(),    // 1 - instead of me, 2 - instead of another member
        replacing: yup.string().required().notOneOf(["0"], "Invalid selection"),
        delegateEmail: yup.string().required("Delegate email is required")
            .matches(emailRegex, "Only alphanumeric characters accepted")
            .transform((value, origValue) => `${value}${process.env.REACT_APP_FACULTY_DOMAIN}`)
            .notOneOf([yup.ref("replacing")], "You cannot assign youself as a delegate"),
        delegateName: yup.string().required("Delegate name is required")
    })

    const { getDepartmentRoles, getDelegates, addDelegate, deleteDelegate } = useDataService();

    const initializeData = () => {
        if (isAdmin) {
            getDepartmentRoles(departmentID)
                .then(roles => setMembers(roles.filter(r => r.role === Role.CommitteeMember.role)))
                .catch(error => console.log(error))
        }
        getDelegates(caseID, caseIDs)
            .then(delegates => setDelegates(delegates))
            .catch(err => console.log(err))
    };

    const handleDelete = (email) => {
        setError();
        deleteDelegate(caseID, caseIDs, email)
        .then(delegates => setDelegates(delegates))
        .catch(error => {
            setError(error.message);
        })
    }

    const handleSubmit = (formData) => {
        setError();
        setSubmitting(true);
        addDelegate(caseID, formData)
            .then(result => {
                setSubmitting(false);
                onHide();
            })
            .catch(error => {
                setSubmitting(false);
                if (error.code === 'ER_DUP_ENTRY')
                    setError("A delegate assignment with this member and/or delegate already exists");
                else
                    setError(error.message);
            })
    }


    const rolesInDept = user.userData.roles.filter(r => r.departmentId === departmentID);
    const isMember = rolesInDept.find(r => r.role === Role.CommitteeMember.role) ? true : false;
    const isAdmin = rolesInDept.find(r => r.role === Role.DepartmentAdministrator.role) ? true : false;

    return (
        <Modal show={show} onHide={onHide} onEnter={initializeData} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Assign a delegate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="pb-3">
                    {delegates.length > 0 &&
                        <Card bg="light"className="mb-2">
                            <Card.Header>Current delegate assignments</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={5}>
                                        <Card.Text><strong>Member</strong></Card.Text>
                                    </Col>
                                    <Col md={5}>
                                        <Card.Text><strong>Delegate</strong></Card.Text>
                                    </Col>
                                </Row>
                                {delegates.map((d, i) => (
                                    <Row>
                                        <Col md={5}>
                                            <Card.Text>
                                                {d.defaultReviewerEmail}
                                            </Card.Text>
                                        </Col>
                                        <Col md={5}>
                                            <Card.Text>
                                                {d.delegateReviewerEmail}
                                            </Card.Text>
                                        </Col>
                                        <Col md={2}>
                                            <div style={{ textAlign: "center", fontSize: "1.3em" }}>
                                                    <FontAwesomeIcon
                                                        className="trash-delete"
                                                        icon={faTrashAlt}
                                                        onClick={() => handleDelete(d.defaultReviewerEmail)}
                                                    />
                                                </div>
                                        </Col>
                                    </Row>
                                )
                                )}
                            </Card.Body>
                        </Card>
                    }
                    <Formik
                        initialValues={{
                            option: isAdmin ? "2" : "1",
                            replacing: isAdmin ? "0" : user.userData.email,
                            delegateEmail: "",
                            delegateName: ""
                        }}
                        validationSchema={delegateSchema}
                        onSubmit={(values) => handleSubmit(values)}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                            values,
                            errors
                        }) => (
                            <Form noValidate onSubmit={handleSubmit} id="delegate-form">
                                {isMember && isAdmin &&
                                    <Form.Group>
                                        <Form.Label>Assign another person</Form.Label>
                                        <Form.Check
                                            type="radio"
                                            name="option"
                                            id="option-1"
                                            onChange={(e) => {
                                                setFieldValue("replacing", user.userData.email);
                                                handleChange(e);
                                            }}
                                            label="Instead of me"
                                            value="1"
                                            checked={values.option === '1'}
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="option"
                                            id="option-2"
                                            onChange={(e) => {
                                                setFieldValue("replacing", "0");
                                                handleChange(e);
                                            }}
                                            label="Instead of another member"
                                            value="2"
                                            checked={values.option === '2'}
                                        />
                                        {errors?.option &&
                                            <div className="invalid-feedback d-block">{errors?.option}</div>
                                        }
                                    </Form.Group>
                                }
                                <Form.Group controlId="replacing" className="mt-2">
                                    <Form.Label>Replace member:</Form.Label>
                                    {values.option === '1' &&
                                        <Form.Control
                                            name="replacing"
                                            type="text"
                                            value={values.replacing}
                                            disabled={true}
                                        />
                                    }
                                    {values.option === '2' &&
                                        <Form.Select
                                            name="replacing"
                                            onChange={handleChange}
                                            value={values.replacing}
                                            isInvalid={!!errors.replacing}
                                        >
                                            <option value="0">-- Select existing member --</option>
                                            {members.map((m, i) => (
                                                <option key={i} value={m.email}>{m.name} ({m.email})</option>
                                            ))}
                                        </Form.Select>
                                    }
                                    <div className={`invalid-feedback ${values.option === '2' && errors.replacing ? 'd-block' : 'd-none'}`}>
                                        {errors.replacing}
                                    </div>
                                </Form.Group>
                                <Row className="mt-2">
                                    <Form.Group as={Col} controlId="delegateEmail">
                                        <Form.Label>Delegate email</Form.Label>
                                        <InputGroup className="mb-2">
                                            <FormControl
                                                aria-label="Delegate email"
                                                aria-describedby="facultyDomain"
                                                name="delegateEmail"
                                                type="text"
                                                value={values.delegateEmail}
                                                placeholder="Email"
                                                onChange={handleChange}
                                                isInvalid={!!errors.delegateEmail}

                                            />
                                            <InputGroup.Text id="facultyDomain">{process.env.REACT_APP_FACULTY_DOMAIN}</InputGroup.Text>
                                            <Form.Control.Feedback type="invalid">{errors.delegateEmail}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="delegateName">
                                        <Form.Label>Delegate Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="delegateName"
                                            value={values.delegateName}
                                            placeholder="Name"
                                            onChange={handleChange}
                                            isInvalid={!!errors.delegateName}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.delegateName}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                {error && <div className="invalid-feedback d-block">{error}</div>}
                            </Form>
                        )}
                    </Formik>
                </Container>
                <Modal.Footer>
                    <Button variant="danger" onClick={onHide}>Cancel</Button>
                    <Button
                        variant="primary"
                        type="submit"
                        form="delegate-form"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ?
                            <span>Loading <Spinner as="span" animation="border" size="sm" role="status" /></span>
                            : 'Assign delegate'
                        }
                    </Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}

export default DelegateReviewerForm;