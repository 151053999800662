import { faInfoCircle, faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as yup from 'yup';

function RolesContactsForm(props) {

    const roleAssignmentSchema = yup.object().shape({
        name: yup.string().required('Name is required').max(100),
        email: yup.string().required('Email is required').matches(/^[a-zA-Z0-9._-]+$/, "Only numbers, letters, ._- accepted").max(100),
        role: yup.string().required('Role must be selected').max(50),
        altRole: yup.string().when('role', {
            is: 'Other', 
            then: (schema) => schema.required('Other role must be specified').max(50),
            otherwise: (schema) => schema.nullable()
        })
    })

    const get = props.get;

    const params = useParams();

    const [roles, setRoles] = useState([]);
    const [editing, setEditing] = useState(false);
    const [postError, setPostError] = useState();
    const [deleteError, setDeleteError] = useState();

    useEffect(() => {
        get(params.deptId, params.progId)
            .then(data => {
                setRoles(data);
            })
            .catch(error => console.log(error));
    }, [get])

    const assignUser = (formData, setSubmitting, resetForm) => {
        setPostError();
        setDeleteError();
        setSubmitting(true);
        props.create(params.deptId, formData, params.progId)
        .then(newRoles => {
            setRoles(newRoles)
            resetForm();
        })
        .catch(error => {
            console.log(error);
            if (error.code === 'ER_DUP_ENTRY') {
                setPostError(props.postErrorMessage);
            }
        }) 
        .finally(() => setSubmitting(false));
    }

    const removeRole = (role) => {
        setDeleteError();
        props.delete(params.deptId, role, params.progId)
        .then(newRoles => setRoles(newRoles))
        .catch(error => {
            console.log(error)
            if (error.code === 'ER_SIGNAL_EXCEPTION') {
                setDeleteError(error.message);
            }
        });
    }

    return (
        <Container className="mt-4 mb-4">
            <Row className="mb-3 justify-content-between">
                <Col md="auto" className="d-flex">
                    <h4 className="me-4">{props.sectionHeader}</h4>
                    <h4>
                        <OverlayTrigger
                            placement="right"
                            delay={{show: 250, hide: 400}}
                            overlay={<Tooltip id="test">{props.infoMessage}</Tooltip>}
                        >
                            <a className="info-hover"><FontAwesomeIcon icon={faInfoCircle}/></a>
                        </OverlayTrigger>
                    </h4>
                </Col>
                <Col md="auto">
                    <Button variant={editing ? 'secondary' : 'outline-secondary'} onClick={() => setEditing(!editing)}>
                        <span><FontAwesomeIcon icon={faPencilAlt}/> Edit</span>
                    </Button>
                </Col>
            </Row>
            <Table bordered>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        {editing && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {roles.map(r => (
                        <tr key={r.id}>
                            <td>{r.name}</td>
                            <td>{r.email}</td>
                            <td>{r.role}</td>
                            {editing && <td className="text-center">
                                <FontAwesomeIcon 
                                    className="trash-delete" 
                                    icon={faTrashAlt}
                                    size="lg" 
                                    onClick={() => removeRole(r.id)}
                                />
                            </td>}
                        </tr>
                    ))}
                    {roles.length === 0 &&
                        <tr>
                            <td className="text-center" colSpan={editing ? 4 : 3}><i>{props.emptyTableMessage}</i></td>
                        </tr>
                    }
                </tbody>
            </Table>
            <div className={`invalid-feedback ${deleteError ? 'd-block' : 'd-none'}`}>
                {deleteError}
            </div>
            {editing &&
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    role: props.options[0],
                    altRole: ""
                }}
                validationSchema={roleAssignmentSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => assignUser(values, setSubmitting, resetForm)}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <h5 className="mt-4 mb-3">{props.formHeader}</h5>
                        <Row>
                            <Form.Group as={Col} controlId="name" label="User's name">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="User's name"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <Col>
                                <Form.Label>Email</Form.Label>
                                <InputGroup className="mb-2" hasValidation>
                                    <Form.Control
                                        id="email"
                                        name="email"
                                        type="text"
                                        placeholder="User's email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}

                                    />
                                    <InputGroup.Text id="facultyDomain">{process.env.REACT_APP_FACULTY_DOMAIN}</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="justify-content-between">
                            <Form.Group as={Col} md={5} controlId="role">
                                <Form.Label>Role</Form.Label>
                                <Form.Select
                                    name="role"
                                    onChange={handleChange}
                                    value={values.role}
                                    isInvalid={!!errors.role}
                                >
                                    {props.options.map((opt, i) => (
                                        <option key={opt} value={opt}>{opt}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
                            </Form.Group>
                            {values.role === 'Other' &&
                                <Form.Group as={Col} controlId="altRole">
                                    <Form.Label>Specify other:</Form.Label>
                                    <Form.Control
                                        name="altRole"
                                        type="text"
                                        onChange={handleChange}
                                        value={values.altRole}
                                        isInvalid={!!errors.altRole}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors?.altRole}</Form.Control.Feedback>
                                </Form.Group>
                            }
                            <Col md={2} className="align-self-end text-end">
                                <Button className="w-100" type="submit">Add</Button>
                            </Col>
                        </Row>
                        <div className={`invalid-feedback ${postError ? 'd-block' : 'd-none'}`}>
                            {postError}
                        </div>
                    </Form>
                )}

            </Formik>
            }
        </Container>
    )
}
export default RolesContactsForm;