import Evidence from "./Evidence";

export default class CaseDetails {
    incidentDescription="";
    altIncidentDescription="";
    specsFiles=[];
    evidence=[new Evidence()];

    setInfo = function(desc, altDesc, specs) {
        this.incidentDescription = desc;
        this.altIncidentDescription = altDesc;
        this.specsFiles = specs;
    };

    isEmpty = function() {
        return this.incidentDescription === "" || this.evidence[0].isEmpty();
    }

}