import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SortableColumnHeader.css';

function SortableColumnHeader(props) {
    return (
        <div className="d-flex justify-content-between mb-2 header-sortable">
            <span>{props.text}</span>
            <div onClick={() => props.toggleSort(props.index)}>
                {!props.sorted &&
                    <FontAwesomeIcon icon={faSort} />
                }
                {props.sorted && props.asc &&
                    <FontAwesomeIcon icon={faSortUp} />
                }
                {props.sorted && !props.asc &&
                    <FontAwesomeIcon icon={faSortDown} />
                }
            </div>
        </div>
    )
}

export default SortableColumnHeader;