import { Col, Form, Row } from "react-bootstrap";
import { incidentDescriptionList } from "../../helpers/helpers";

function IncidentDescriptionSelection(props) {

    return (
        <Row>
            <Form.Group as={Col} controlId={`${props.target}.incidentDescription`}>
                <Form.Label>Incident Description:</Form.Label>
                <Form.Select
                    aria-label="incidentDescription"
                    name={`${props.target}.incidentDescription`}
                    onChange={props.handleChange}
                    value={props.values.incidentDescription}
                    isInvalid={!!props.errors?.incidentDescription}
                >
                    {
                        incidentDescriptionList.map((e, i) => (
                                <option key={i} value={e}>{e}</option>

                            ))
                    }
                    <option value="Other">Other</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{props.errors?.incidentDescription}</Form.Control.Feedback>
            </Form.Group>
            {props.values.incidentDescription === 'Other' &&
                <Form.Group as={Col} md={8} controlId={`${props.target}.altIncidentDescription`} className="mt-1">
                    <Form.Label>Specify other:</Form.Label>
                    <Form.Control
                        name={`${props.target}.altIncidentDescription`}
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.altIncidentDescription}
                        isInvalid={!!props.errors?.altIncidentDescription}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors?.altIncidentDescription}</Form.Control.Feedback>
                </Form.Group>
            }
        </Row>
    )

}

export default IncidentDescriptionSelection;