import Role from "./roles";

const MENU_ITEMS = {
    studentsCases: {
        title: 'My Incidents',
        path: '/student/cases'
    },
    createCase: {
        title: 'Create Case',
        path: '/create-case'
    },
    professorsCases: {
        title: 'My Incidents',
        path: '/faculty/cases'
    },
    committeesCases: {
        title: 'Academic Integrity Cases',
        path: '/cases'
    },
    priors: {
        title: 'Add priors',
        path: '/add-priors'
    },
    decisionLetters: {
        title: 'Decision Letters',
        path: '/cases/letters'
    },
    adminDepartments: {
        title: 'Manage Departments',
        path: '/departments'
    },
    dashboard: {
        title: 'Dashboard',
        path: '/dashboard'
    }
}

const getHomePage = roles => {
    let homePage = {path: '/not-found'};
    if (roles.find(r => r.role === Role.Student.role)) {
        homePage = MENU_ITEMS.studentsCases;
    } else if (roles.find(r => r.role === Role.Professor.role)) {
        if (roles.find(r => [Role.DepartmentAdministrator.role, Role.CommitteeMember.role].includes(r.role))) {
            homePage = MENU_ITEMS.dashboard;
        }

        if (roles.find(r => r.role === Role.SystemAdministrator.role)) {
            homePage = MENU_ITEMS.adminDepartments;
        }

        if (homePage.path === '/not-found') {
            homePage = MENU_ITEMS.professorsCases;
        }
    }

    return homePage;
}

const incidentDescriptionList = [
    '-- Choose Incident Description --',
    'Student cheated from another student',
    'Student cheated from the internet',
    'Student cheated from a group of different students'
];

const supportedImageExtensions = [
    '.jpg', 
    '.jpeg', 
    '.png',
    '.webp'
];

const stringifyCaseDetails = caseDetails => {
    return {
        incidentDescription: caseDetails.incidentDescription === 'Other' ? caseDetails.altIncidentDescription : caseDetails.incidentDescription,
    }
}

const openInNewTab = url => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
}

const holdItemsList = [
    'None',
    'Academic Warning',
    'Academic Warning and First Offence',
    'First Offence',
    'Second Offence',
    'Third Offence'
]

const incidentTypeList = [
    'Plagiarism',
    'Contract cheating',
    'Copying'
];

const statusDescriptions = [
    'These incidents have just been reported',
    'Time window for student response has expired',
    'These incidents are locked for decision entry',
    'A decison has been recorded, letters need to be sent',
    'These incidents are complete'
];

const getSemesterOptions = () => {
    const winterStart = {
        month: 0,
        day: 1
    }
    const fallStart = {
        month: 8,
        day: 1
    }
    const summerStart = {
        month: 4,
        day: 1
    }

    let options = [];
    const currMonth = new Date().getMonth();
    const currYear =  new Date().getFullYear();
    if (currMonth >= winterStart.month && currMonth < summerStart.month) {
        options.push(`Winter ${currYear}`)    // winter of current year
        options.push(`Fall ${currYear - 1}`)  // fall of previous year
    } else if (currMonth >= summerStart.month && currMonth < fallStart.month) {
        options.push(`Summer ${currYear}`);   // summer of current year
        options.push(`Winter ${currYear}`);   // winter of current year
    } else if (currMonth >= fallStart.month) {
        options.push(`Fall ${currYear}`)      // fall of current year
        options.push(`Summer ${currYear}`)    // summer of current year
    }

    return options;
}

export {
    MENU_ITEMS,
    getHomePage,
    incidentDescriptionList,
    supportedImageExtensions,
    stringifyCaseDetails,
    openInNewTab,
    holdItemsList,
    incidentTypeList,
    statusDescriptions,
    getSemesterOptions
}