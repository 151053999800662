import { Container, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function ConfirmationPage(props) {
    return (
        <Container>
            <h2>Incident reported</h2>
            <p>You have successfully reported the following incident(s). Students have been notified of the report.</p>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Case#</th>
                        <th>Student Name</th>
                        <th>Incident Description</th>
                    </tr>
                </thead>
                <tbody>
                    {props.responseData.map((c, i) => (
                        <tr key={i}>
                            <td>{c.caseID}</td>
                            <td>{c.studentName}</td>
                            <td>{c.incidentDescription}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <p>Please submit assessment specifications and evidence for these incidents within 5 days.<br/>
                Proceed to <Link to="/faculty/cases">incidents reported by you</Link> to edit attachments.
            </p>
        </Container>
    )
}

export default ConfirmationPage;