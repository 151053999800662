import { useEffect, useState } from 'react';
import { Button, Container, Tab, Tabs } from 'react-bootstrap';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useDataService from '../../hooks/useDataService';
import AccessDenied from '../Errors/AccessDenied';
import NotFound from '../Errors/NotFound';
import Department from './Department';
import Program from './Program';
import ProgramsList from './ProgramsList';

function DepartmentManagement(props) {
    const params = useParams();
    const [key, setKey] = useState('deptInfo');

    const [loading, setLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [department, setDepartment] = useState(null);

    const [error, setError] = useState();

    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    }


    let pathname = usePathname();
    const { getDepartmentById } = useDataService();

    useEffect(() => {
        setLoading(true);
        getDepartmentById(params.deptId)
            .then(data => {
                setDepartment(data);
                setLoading(false);
                let relativePath = pathname;
                if (relativePath.includes("programs")) setKey("programs");
            })
            .catch(err => {
                setLoading(false);
                if (err.code === 'FORBIDDEN') {
                    setForbidden(true);
                } else if (err.code === 'ER_NOT_FOUND') {
                    setNotFound(true);
                } else {
                    setError(err.message.toString());
                }
            })
    }, [params.deptId]);

    const navigate = useNavigate();

    function handleTabClick(k) {
        let link = `/departments/${params.deptId}`;
        if (k == "programs") link += "/programs";
        navigate(link);
    }

    if (loading) {
        return <p>Loading...</p>;
    }

    if (forbidden) {
        return <AccessDenied />;
    }

    if (notFound) {
        return <NotFound />
    }

    if (department) {
        return (
            <Container className="w-75">
                <Button variant="outline-danger" className="mb-4" onClick={() => navigate(`/departments`)}>
                    <span>&lt; Back to departments</span>
                </Button>
                <h2 className="mb-4">{department.fullName}</h2>
                <Tabs defaultActiveKey="deptInfo" activeKey={key} onSelect={k => { setKey(k); handleTabClick(k) }}>
                    <Tab
                        eventKey="deptInfo"
                        title="Department Information"
                        href="/departments/${params.id}"
                    >
                        <Department department={department} setDepartment={setDepartment} />
                    </Tab>
                    <Tab
                        eventKey="programs"
                        title="Programs"
                    >
                        <Routes>
                            <Route path="/programs" element={<ProgramsList />} />
                            <Route path="/programs/:progId" element={<Program />} />
                        </Routes>
                    </Tab>
                </Tabs>
            </Container>
        )
    }

    return null;
}

export default DepartmentManagement;