
import { Formik } from 'formik';
import { useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import useDataService from '../../hooks/useDataService';

function NewDepartmentForm({ show, handleClose, setDepartments, ...props }) {

    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState();

    const { addDepartment } = useDataService();

    const departmentSchema = yup.object().shape({
        name: yup.string().required('Name is required').max(10),
        fullName: yup.string().required('Full Name is required').max(100),
        adminName: yup.string().required('Dept Admin is required').max(100),
        adminEmail: yup.string().required('Email is required').matches(/^[a-zA-Z0-9._-]+$/, "Only numbers, letters, ._- accepted").max(100)
    });

    const createDepartment = (formData) => {
        setError();
        setSubmitting(true);
        addDepartment(formData)
            .then(depts => {
                setSubmitting(false);
                setDepartments(depts);
                handleClose();
            })
            .catch(error => {
                setSubmitting(false)
                console.log(error);
                setError(error.message.toString());
            });
    }

    return (
        <Modal show={show} backdrop="static" size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create new department</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Formik
                        initialValues={{
                            name: "",
                            fullName: "",
                            adminName: "",
                            adminEmail: ""
                        }}
                        validationSchema={departmentSchema}
                        onSubmit={(values) => createDepartment(values)}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors
                        }) => (
                            <Form noValidate onSubmit={handleSubmit} id="new-dept-form" className="mb-5">
                                <h5 className="mt-3">Department Information</h5>
                                <Row>
                                    <Col md={3}>
                                        <Form.Group as={Col} controlId="name">
                                            <Form.Label>Short Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder="DPRT"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group as={Col} controlId="fullName">
                                            <Form.Label>Department Full Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="fullName"
                                                placeholder="Department of Example"
                                                value={values.fullName}
                                                onChange={handleChange}
                                                isInvalid={!!errors.fullName}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.fullName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <h5 className="mt-3">Add Department Admin</h5>
                                <Row>
                                    <Form.Group as={Col} controlId="adminName">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="adminName"
                                            placeholder="Admin's Name"
                                            value={values.adminName}
                                            onChange={handleChange}
                                            isInvalid={!!errors.adminName}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.adminName}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Col>
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup className="mb-2" hasValidation>
                                            <Form.Control
                                                id="adminEmail"
                                                name="adminEmail"
                                                type="text"
                                                placeholder="User's adminEmail"
                                                value={values.adminEmail}
                                                onChange={handleChange}
                                                isInvalid={!!errors.adminEmail}

                                            />
                                            <InputGroup.Text id="facultyDomain">{process.env.REACT_APP_FACULTY_DOMAIN}</InputGroup.Text>
                                            <Form.Control.Feedback type="invalid">{errors.adminEmail}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {error &&
                                    <div className='invalid-feedback d-block'>
                                        {error}
                                    </div>
                                }
                            </Form>
                        )}
                    </Formik>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>Cancel</Button>
                <Button
                    type="submit"
                    form="new-dept-form"
                    disabled={isSubmitting}
                >
                    {isSubmitting ?
                        <span>Loading <Spinner as="span" animation="border" size="sm" role="status" /></span>
                        : 'Create department'
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NewDepartmentForm;